import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgForOf } from '@angular/common';
import { NgIf } from '@angular/common';
import { 
  formatDate 
 } 
  from '@angular/common';

@Component({
  selector: 'app-timelines',
  standalone: true,
  imports: [MatCardModule,NgForOf,NgIf],
  templateUrl: './timelines.component.html',
  styleUrl: './timelines.component.css'
})
export class TimeLineComponent {
@Input() timeLines:any;
ngOnInit(): void {
  if(this.timeLines){
for(let i=0;i<this.timeLines.length;i++){
  if(this.timeLines[i].type=="Sleep"){
    let data = this.timeLines[i].data;
    this.timeLines[i].data.time = formatDate(data.startTime, 'yyyy-MM-dd hh:mm a','en-US'); 
    const hours = Math.floor(data.duration / 3600);
    const minutes = Math.floor((data.duration % 3600) / 60);
    this.timeLines[i].data.toDisplay = hours + ' hrs ' + minutes + ' min';
  }else if(this.timeLines[i].type=="Activity"){
    let data = this.timeLines[i].data;
    this.timeLines[i].data.time = formatDate(data.startTime, 'yyyy-MM-dd hh:mm a','en-US');
    this.timeLines[i].data.toDisplay = (data.distanceCovered/1000).toFixed(1) + ' km';
  }else{
    this.timeLines[i].data.time = formatDate(this.timeLines[i].data.time, 'yyyy-MM-dd hh:mm a','en-US');
    this.timeLines[i].data.toDisplay = this.timeLines[i].data.calories+' cal';
  }
}
  }
}
}
