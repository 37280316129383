import { Component, Input } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, formatDate, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-member-meal-details',
  standalone: true,
  imports: [BasicDetailsComponent,MatCardModule,CommonModule,NgIf,RouterLink],
  templateUrl: './member-meal-details.component.html',
  styleUrl: './member-meal-details.component.css'
})
export class MemberMealDetailsComponent {
  @Input() memberMealDetails:any;
  @Input() selectedTab:any;
  date:any = "";
  ngOnInit(): void {      
   let d = this.memberMealDetails.mealDueDate.split('T')[0];
   d = d.split('-');
   this.date = formatDate(new Date(d[0],d[1]-1,d[2]), 'dd MMM yyyy','en-US');

  }
}
