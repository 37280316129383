<div class="col-md-12 flex">
    <div class="col-md-9 float">
        <div class="col-md-7 mainSleep float">
            
                <div class="col-md-12 ml-3">
                         <highcharts-chart 
  [Highcharts]="Highcharts"
  [options]="coptions"
  style="max-height: 100%; display: block;"
></highcharts-chart>
                </div>
        </div>
        <div class="col-md-5 float ">
            <div class="col-md-12 float sleepSub ml-1">
                <p><img class="img" src="/assets/img/heat.png"/></p>
                <p class="totalBurn">Total Calories Burned</p>
                <p class="totalCount">{{activity && activity.calsBurntStatus?activity.totalCalsBurnt+ ' cal':'---'}}</p>
                <p><button class="btn btnClass" [style.background-color]="calsBtnColor">{{calsStatus}}</button></p>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-4 float" style="margin-top:-70px">
        <div class="noteSection">Notes Section</div>
        <app-notes></app-notes>
    </div> -->
    
    </div>