import { Component } from '@angular/core';
import { MealMemberBasicDetailsComponent } from '../meal-member-basic-details/meal-member-basic-details.component';
import { MealPlanService } from '../meal-plan.service';
import { ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { NgFor } from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { ToastService } from '../../services/toast.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { 
  formatDate 
 } 
  from '@angular/common'; 
  import { PreferenceComponent } from '../preference/preference.component';

  import { Preference } from '../../model/preference';
  import { ResultMealPlanComponent } from '../result-meal-plan/result-meal-plan.component';
  import { NgIf } from '@angular/common';
  import { Router } from "@angular/router";
  
  import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-create-plan',
  standalone: true,
  imports: [NgxMaterialTimepickerModule,MatProgressSpinnerModule,NgIf,PreferenceComponent,ResultMealPlanComponent,MealMemberBasicDetailsComponent,FormsModule,NgClass,NgFor,MatDateRangeInput,MatDatepickerModule,MatFormFieldModule,MatLabel,MatHint,MatNativeDateModule,MatInputModule],
  templateUrl: './create-plan.component.html',
  styleUrl: './create-plan.component.css'
})

export class CreatePlanComponent {
    target:any=[];
    dailyTarget:any;
    allPrefer:any={};
    memberId:any;
    userLoggedIn:any = '';
    deficit:any;
    allDates:any=[];
    meal:any=[];
    createMeal:any=[];
    mealResult:any=[];
    iscreateMeal:boolean=true;
    isResult:boolean=false;
    isBreakfast:any=1;
    isLunch:any=1;
    isDinner:any=1;
    isSnack:any=1;

    isBreakCross:any=1;
    isLunchCross:any=1;
    isDinnerCross:any=1;
    isSnackCross:any=1;
    isCuisineDefault:any=1;
    memberDetails:any=[];
    backType = 1;
    isSingle:boolean=false;
    assignMealPlan:any={
      memberId:'',
      fromDate:'',
      toDate:''
    };
    
    isLoading:any=2;
    todayDate:Date = new Date();
    checkSelectedMealType:any=[0,1,1,1,1];
    actualWeight:any = 0; 
    isExistPlan = 1;

    constructor(private mealService:MealPlanService,private route:ActivatedRoute,private router:Router,private toast:ToastService) {
      
    }

    ngOnInit(): void {
      this.memberId = this.route.snapshot.queryParamMap.get('memberId');
      this.getTarget();
    //   this.getMemberDetails( this.memberId);
    //   this.mealService.getVital(this.memberId).subscribe(response => { 
    //    this.actualWeight =response;
    //   //console.log( this.actualWeight);
    //    if(this.actualWeight.result.bmiData !== null){
    //     this.actualWeight = this.actualWeight.result.bmiData.weight;
    //    }else{
    //     this.actualWeight = 0;
    //    }
       
    //  //  console.log(response);
    // });
    }

    // getMemberDetails(id:any){
    //     this.mealService.getMemberDetails(id).subscribe(response => { 
    //       this.memberDetails = response;
    //       this.memberDetails = this.memberDetails.result;
    //      // console.log(this.memberDetails);
    //   });
    // }
    getTarget(){
      this.mealService.getDailyTarget(this.memberId).subscribe(response => { 
        this.target = response;
        this.dailyTarget = this.target.memberTarget.caloriesNeeded;
        this.deficit = this.target.memberTarget.caloriesDeficitGoal;
        this.dailyTarget = this.dailyTarget - this.deficit;
        this.getPreference();
    });
    }

    getPreference(){
      
      this.mealService.getPreference(this.memberId).subscribe(response => {   
       
        this.allPrefer = response;
        let prepTimes=[30,20,10,20];
       
        if(this.allPrefer.preferences !== undefined){
        //  console.log('here',this.allPrefer.preferences);
        this.allPrefer = this.allPrefer.preferences;
       
         prepTimes=[this.allPrefer.lifeStyle.bPrepTime,this.allPrefer.lifeStyle.lPrepTime,this.allPrefer.lifeStyle.dPrepTime,this.allPrefer.lifeStyle.sPrepTime];
      }else{
       // console.log('sec');
        this.allPrefer={
          id:'',
          memberId:this.memberId,
          cuisine:{
              defaultCuisines:[],
              likedCuisines:[],
              dislikedCuisines:[],
              foodRestrictions:[],
          },
          allergies:[],
          likedIngredients:{
            "breakfast": [],
    "lunch": [],
    "dinner": [],
    "snack": []
          },
          dislikedIngredients:{
            "breakfast": [],
    "lunch": [],
    "dinner": [],
    "snack": []
          },
          lifeStyle:{
              bPrepTime:0,
              lPrepTime:0,
              dPrepTime:0,
              sPrepTime:0,
              isKidFriendly:true,
              isMealPlanningFriendly:true
          }
        };
      }

      let type=['Breakfast','Lunch','Dinner','Snack'];
      let percentage = ['35','35','20','10'];
      
      for(let i=0;i<4;i++){
          this.meal.push({percentage:percentage[i],type:type[i],calories:this.getCal(percentage[i]),macroBreakdown:{protein:50,carbs:30,fat:20},prepTime:prepTimes[i],time:''});
      }
        //console.log(this.meal);
      });

      
    }

    getCal(percentage:any){ 
      let val = this.dailyTarget - this.deficit;     
      return (val*percentage)/100;
  }

  changeDailyTarget(){
    for(let i=0;i<this.meal.length;i++){
      this.meal[i].calories = this.getCal(this.meal[i].percentage);
  }
  }

  createMealPlan(){
    //console.log('sdd');
    if(this.allDates.length < 1){
      this.toast.errorMessage('Please choose the dates.');
    }else{
    let fromDate = formatDate(this.allDates[0].planStartDate, 'yyyy-MM-dd', 'en');
    let toDate = formatDate(this.allDates[0].planEndDate, 'yyyy-MM-dd', 'en');
    const timeVal = this.meal.filter((x:any) => x.time == ''); 
   // console.log(this.meal);
   // console.log(timeVal.length);
    if(this.dailyTarget < 1){
      this.toast.errorMessage('Daily target calories must be greater than 0.');
    }
    else if(this.checkSelectedMealType[1] == 2 && this.checkSelectedMealType[2] == 2 && this.checkSelectedMealType[3] ==2 && this.checkSelectedMealType[4] == 2){
      this.toast.errorMessage('Please add atleast one meal.');
    }
    else if(timeVal.length > 0){
        this.toast.errorMessage('Please add the meal time.');
    }
    else if(this.isExistPlan == 2){
      this.toast.errorMessage('The meal plan has already been designated for the chosen dates..');
    }
    else if(fromDate <= toDate){
      this.createMeal = {calsNeeded:this.dailyTarget,planStartDate:this.dateFormat(this.allDates[0].planStartDate),planEndDate:this.dateFormat(this.allDates[0].planEndDate),meals:this.meal,cuisinePreference:{default:this.allPrefer.cuisine.defaultCuisines,liked:this.allPrefer.cuisine.likedCuisines,disliked:this.allPrefer.cuisine.dislikedCuisines,foodRestrictions:this.allPrefer.cuisine.foodRestrictions,allergies:this.allPrefer.allergies,ingredientPreferences:{liked:this.allPrefer.likedIngredients,disliked:this.allPrefer.dislikedIngredients,lifeStyle:{isKidFriendly:this.allPrefer.lifeStyle.isKidFriendly,isMealPlanningFriendly:this.allPrefer.lifeStyle.isMealPlanningFriendly}}}};
      this.mealService.addPreference(this.allPrefer).subscribe(response => { 
       
    });
    this.assignMealPlan.memberId = this.memberId;
    this.assignMealPlan.fromDate = this.dateFormat(this.allDates[0].planStartDate);
    this.assignMealPlan.toDate = this.dateFormat(this.allDates[0].orignialEndDate);
    this.assignMealPlan.name = this.memberDetails.firstName+' Meal Plan';
      this.isLoading = 1;
     this.mealService.createMealPlan(this.createMeal).subscribe(response => { 
      this.isLoading = 2;
     // console.log(response);
      if(response === 1){
        this.toast.errorMessage('Api through error.Please try again.');
      }else{
        this.mealResult = response;
        this.mealResult = this.mealResult.meals;
        this.isResult = true;
        this.iscreateMeal = false;           
        this.backType = 2;
      //  this.ToastrService.success('Meals fetch successfully.');
      this.toast.successMessage('Meals fetch successfully.');
      }
      
              
          });

        }else{
        //  this.ToastrService.error('To date always above from date.');
          //console.log('To date always above from date.');
         
            //console.log('Duplicate Time Exist');
            this.toast.errorMessage('To date always above from date.');
                     
        }
      }
    }

    calculateDiff(start:any,end:any) {
      
      var date1:any = new Date(start);
      var date2:any = new Date(end);
      var diffDays:any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
  
      return diffDays;
  }

  getSelectedDate(startDt:HTMLInputElement,endDt:HTMLInputElement){
    this.allDates = [];
    if(startDt.value != '' && endDt.value != ''){
      let diff = this.calculateDiff(startDt.value,endDt.value) + 1;
      //console.log(diff);
      this.mealService.checkIsPlanAssign(this.memberId,startDt.value,endDt.value).subscribe(response => { 
        let isExistPlan:any;
        isExistPlan = response;
        if(isExistPlan.isExist == true){
          this.toast.errorMessage('The meal plan has already been assigned for the chosen dates.')
          
          this.isExistPlan = 2;
        }else{
          let newDt:any = endDt.value;
          let dt= new Date(startDt.value);
          if(diff > 7){
            dt.setDate( dt.getDate() + 6 );
            //console.log(dt.getDate());
            newDt = this.dateFormat(dt);
          }
         

          this.isExistPlan = 1;
          this.allDates.push({planStartDate:startDt.value,planEndDate:newDt, orignialEndDate:endDt.value});
         // console.log(this.allDates);
        }
       
     });
    }

    //console.log(this.allDates);
  }

  dateFormat(date:any){
    return formatDate(date, 'yyyy-MM-dd','en-US');
  }

  removeMealType(type:any,index:any){
    this.checkSelectedMealType[type] = 2;
   // console.log( this.checkSelectedMealType);
      this.showHideMeal(type,2,index);
  }

  addMealType(type:any,index:any){
    this.checkSelectedMealType[type] = 1;
    this.showHideMeal(type,1,index);
  }

  showHideMeal(type:any,val:any,index:any){
    if(type == 1){
      this.isBreakfast =val;
      this.isBreakCross =val;
  }
  else if(type == 2){
    this.isLunch = val;
    this.isLunchCross = val;
}
 else  if(type == 3){
    this.isDinner =val;
    this.isDinnerCross =val;
}
else if(type == 4){
  this.isSnack =val;
  this.isSnackCross =val;
}


let type1=['Breakfast','Lunch','Dinner','Snack'];
        let percentage = ['35','35','20','10'];
        let prepTimes=[this.allPrefer.lifeStyle.bPrepTime,this.allPrefer.lifeStyle.lPrepTime,this.allPrefer.lifeStyle.dPrepTime,this.allPrefer.lifeStyle.sPrepTime];
       this.meal = [];
        for(let i=0;i<4;i++){
          if(this.isBreakfast == 1 && i==0){
            this.meal.push({percentage:percentage[i],type:type1[i],calories:this.getCal(percentage[i]),macroBreakdown:{protein:50,carbs:30,fat:20},prepTime:prepTimes[i]});
          }
          else if(this.isLunch == 1 && i==1){
            this.meal.push({percentage:percentage[i],type:type1[i],calories:this.getCal(percentage[i]),macroBreakdown:{protein:50,carbs:30,fat:20},prepTime:prepTimes[i]});
          }
          else if(this.isDinner == 1 && i==2){
            this.meal.push({percentage:percentage[i],type:type1[i],calories:this.getCal(percentage[i]),macroBreakdown:{protein:50,carbs:30,fat:20},prepTime:prepTimes[i]});
          }
          else if(this.isSnack == 1 && i==3){
            this.meal.push({percentage:percentage[i],type:type1[i],calories:this.getCal(percentage[i]),macroBreakdown:{protein:50,carbs:30,fat:20},prepTime:prepTimes[i]});
          }
            
        }
  }
  
  backButton(type:any){
    if(type == 1){
    this.router.navigate(['/meal-plans']);
    }
    if(type == 2){
      this.isResult = false;
      this.iscreateMeal = true;        
      this.backType =1;
    } 
    else if(type == 3){
    //  console.log('ssd');
      this.isResult = true;
      this.isSingle = false;      
    }

  }

  mealDetailBack(type:any){
    this.backType =parseInt(type);
    //console.log(this.backType);
  }

  checkTime(data:any,index:any){
    if(data.target.value != ''){
    const timeVal = this.meal.filter((x:any) => x.time == data.target.value); 
   if(timeVal.length > 1){
    //console.log('Duplicate Time Exist');
    this.toast.errorMessage('Duplicate time exist.');
   
    this.meal[index].time='';
   }
  }
  //console.log(this.meal);
}
}
