<div class="row mt-3 mr-3"  *ngIf="reports.length>0">
    <div class="col-md-4" *ngFor="let report of reports; index as i">
    <div class="col-sm labOutter">
        <div class="col-md-12 padding labTitle flex" >
            <div class="col-md-10">{{report.reportName}}</div>
        </div>
        <div class="col-md-12 mt-3 labTestSub">Received on: {{report.createdDateTime | date:'dd MMM yyyy' }}</div>
         <div class="row mt-4" >
            <div class="col-md-6"><a data-toggle="modal" data-target="#labWorkModal" (click)="viewReport(report.uploadFileURL)"><button class="btn btn1">View File</button></a></div>
           <div class="col-md-6"><button class="btn btn2" (click)="downloadReport(report.uploadFileURL,report.reportName)">Download File</button></div>
        </div>
    </div>
</div>  
</div>

<div class="row mt-2" *ngIf="reports.length==0">No reports found</div>

 <div class="modal fade" id="labWorkModal" tabindex="-1" role="dialog" aria-labelledby="labWorkModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-lg " role="document">
     <div class="modal-content modal-size-style">
       <div class="modal-header">
         <h5 class="modal-title" id="labWorkModalLabel">Report</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
           <span aria-hidden="true">&times;</span>
         </button>
       </div>
       <div class="modal-body">
         <img *ngIf="isImage" width="100%" [src]="modalDocumentURL"/>
         <iframe *ngIf="isImage==false" id="frameStyle" [src]="modalDocumentURL" frameborder="0" width="100%" height="500px"></iframe>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
       </div>
     </div>
   </div>
 </div>