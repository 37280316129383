import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MemberListComponent } from './member/member-list/member-list.component';
import { LoginComponent } from './login/login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetLinkComponent } from './reset-link/reset-link.component';
import { TeamListingComponent } from './team-listing/team-listing.component';
import { MemberDetailsComponent } from './member-details/member-details.component';
import { MemberDashboardComponent } from './member-dashboard/member-dashboard.component';
import { EditFoodComponent } from './member-details/all-food-logs/edit-food/edit-food.component';
import { AuthGuard } from './guards/AuthGuard';
import { MealPlanComponent } from './meal-plan/meal-plan.component';
import { CreatePlanComponent } from './meal-plan/create-plan/create-plan.component';
import { ResultMealPlanComponent } from './meal-plan/result-meal-plan/result-meal-plan.component';
import { AllMealsComponent } from './meal-plan/all-meals/all-meals.component';
import { SearchMealComponent } from './meal-plan/search-meal/search-meal.component';
import { MealDetailsComponent } from './meal-plan/meal-details/meal-details.component';

export const routes: Routes = [
    { path: '', component: LoginComponent},
    { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard] },
    { path: 'members', component: MemberListComponent,canActivate: [AuthGuard] },
    { path: 'forgot-password', component: ForgetPasswordComponent},
    {path:'reset-password', component: ResetPasswordComponent},
    {path:'reset-password-link', component: ResetLinkComponent},
    {path:'teams', component: TeamListingComponent,canActivate: [AuthGuard]},
    {path:'member-details', component: MemberDetailsComponent,canActivate: [AuthGuard]},
    {path:'member-dashboard', component: MemberDashboardComponent,canActivate: [AuthGuard]},
    {path:'edit-food-log', component: EditFoodComponent,canActivate: [AuthGuard]},
    {path:'meal-plans', component: MealPlanComponent,canActivate: [AuthGuard]},
    {path:'create-plan', component: CreatePlanComponent,canActivate: [AuthGuard]},
    {path:'meal-plan-result', component: ResultMealPlanComponent,canActivate: [AuthGuard]},
    {path:'all-meal-plans', component: AllMealsComponent,canActivate: [AuthGuard]},
    {path:'search-result', component: SearchMealComponent,canActivate: [AuthGuard]},
    {path:'meal-details', component: MealDetailsComponent,canActivate: [AuthGuard]},
    
    
    
];
