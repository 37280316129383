<div class="row align-items-start " style="display: flex;margin-top:60px;">

    
        
    <div class="col-md-7 ">
       
        <div class="col-md-12">
            <div class="memberStatTitle">Member Statistics</div>
        </div>
        <!-- <mat-card class="col-md-12 memebrStatsLeft">
            <mat-card-content class="col-md-5">
                <mat-card-title class="subTitle">The members are divided according to the level <br/>care.</mat-card-title>
            </mat-card-content>
            <mat-card-content class="mt-4 paddingZero flex">
                <mat-card-content class="col-md-2">
                    <img mat-card-sm-image class="levelImg" src="/assets/img/L1.png">
                </mat-card-content>
                <mat-card-content class="col-md-8">
                    <mat-card-content class="memberNumber">
                        Number of Members
                    </mat-card-content>
                </mat-card-content>
            </mat-card-content>
          </mat-card> -->
         
        <div class="row mt-3 memebrStatsLeft" >
            <div class="col-md-5" >
               
              <app-member-level [levelCount]="memberCounts.totalMembersCountWithLevels"></app-member-level>
            </div>
            <div class="col-md-7 ">
                <!-- <div class="col-md-5  mt-2 float">
                    <img class="memberImg" src="https://via.placeholder.com/70x70" />
                    <img class="memberImg" mat-card-sm-image style="margin-left:-20px;" src="https://via.placeholder.com/70x70" />
                    <img class="memberImg"mat-card-sm-image style="margin-left:-20px;" src="https://via.placeholder.com/70x70" />
                    <img class="memberImg" mat-card-sm-image style="margin-left:-20px;" src="https://via.placeholder.com/70x70" />
                </div> -->
                <div class="col-md-10 mt-4 ml-4 flex membrNumber" >
                    
                     
                    <div class="col-md-10 ml-4 mt-1 float">
                        <div class="memberCount1">{{memberCounts.totalOnboardedForLastSevenDays}} Members</div>
                    <div class=" onboarded mt-2">Onboarded for last seven days</div>
                </div>
                <div class="col-md-2 float"><img style="width:43px" src="/assets/img/upimg.png"/></div>
            </div>
             <div class="col-md-12 flex mt-5 membrNumber">
            <app-member-team-count [totalCount] = "memberCounts"></app-member-team-count>
        </div>
        </div>
       
    </div>
   
</div>
<div class="eventList" [ngClass]="{'fixHeight': this.eventData.length > 0}">
    <div class="col-md-12 flex eventList" >
        <!-- <div class="col-md-6 upcomingTitle" style="float:right">All Appointments</div>  -->
        <div class="col mt-3"><a href="{{oldDhruva}}calender-event" class="btnAll" color="primary">View All Appointments</a></div>
        <div class="col">
            <mat-form-field>
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" #dateRangeEnd (dateChange)="filterByDate(dateRangeEnd)">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
        </div>
        
    </div>
<app-meeting [meeting]="this.eventData"></app-meeting>
</div>
 <!-- <div class="row  memberPartern fixHeight flex" >

  
    </div> -->