
<mat-card class="col memberPartern memberParternDashboard ml-4" data-ride="carousel" *ngIf="isHide===1">
    
    <mat-card-header>
      
      <app-member-basic-detail [memberbasic]="memberdata" [screentype] = "1" ></app-member-basic-detail>
      
      <!-- <div mat-card-avatar class=""><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
      <mat-card-title class="ml-3">{{memberdata.member}}</mat-card-title>
      <mat-card-subtitle class="ml-3">Age: {{memberdata.age}}</mat-card-subtitle> -->
    </mat-card-header>
     
    <mat-card-content>
       <app-blood-pressure [memberbasic]="memberdata"></app-blood-pressure>
    </mat-card-content>
    <mat-card-actions class="mt-3  ">
        <!-- <a mat-button class="ml-3 callIcon" href="https://www.google.com/" target="_blank"><img mat-card-image class="bloodIcon" src="assets/img/video.png" /></a>
      
        <a mat-button class="ml-3 callIcon" href="https://www.google.com/" target="_blank"><img mat-card-image class="bloodIcon" src="assets/img/msg.png" /></a> -->
        <a  mat-button  data-bs-toggle="modal" [attr.data-bs-target]="'#exampleModal' + memberdata.id"
       class="btnMember" (click)="openPopup(memberdata)" mat-button>Member Advocate</a> &nbsp; &nbsp;
        <a mat-button [routerLink]="['/member-dashboard']" [queryParams]="{ memberId:memberdata.id}"  class="memberDashboard" >Member Dashboard</a>    &nbsp; &nbsp;
        <a  mat-button (click)="openDialog()" class="btnResolve" >Resolve</a>    
        
    </mat-card-actions>
  
  </mat-card>

  <div class="modal fade"   id="exampleModal{{memberdata.id}}" tabindex="-1" aria- 
labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="message" *ngIf="isClose==2">Message sent successfully.</div>
    <div class="modal-body">
        <div class="row">
            
          <div class="col-1"><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
          <div class="col-2 name ml-2 mt-1" >
            <div class="col-md-12 name">
                {{memberDt.personName}}
            </div>
            <div class="col-md-12 age" >
              Age : {{memberDt.age}}
          </div>

    </div>
<!-- 
    <div class="col" >
      <div class="col-md-12  flex">
        <div class="col-md-1 mt-3"><img class="bloodIcon" src="assets/img/blood-pressure 1.png" /></div> 
        <div class="col-md-8 mt-2 "> 
         <div class="col-md-12 title">Blood Pressure</div> 
         <div class="col-md-12 blood mt-1" >
          0 mmhg
      </div>

        </div>

        <div class="col-md-1 mt-3"><img class="bloodIcon" src="assets/img/Up.png" /></div> 
      </div>
      
  


    </div> -->

    <div class="col " >
      <div class="col-md-12  flex">
        <div class="col-md-1 mt-3"><img class="bloodIcon" src="assets/img/blood-pressure 1.png" /></div> 
        <div class="col-md-8 mt-2 "> 
         <div class="col-md-12 title">Blood Glucose</div> 
         <div class="col-md-12 blood mt-1" >
          {{memberDt.glucose}} mg/dl
      </div>

        </div>

        <div class="col-md-1 mt-3"><img class="bloodIcon" src="assets/img/Up.png" /></div> 
      </div>
      
  


    </div>

    <div class="col mt-2" >
    
      <input type="text" readonly value="{{memberDt.memberAdvocateName}}" class="form-control fixWidth"/>
    </div>
        </div>
        
    </div>

   
      <div class="col-md-12 " >
      <textarea class="textField " row="30"  [(ngModel)]="details.message"  [ngModelOptions]="{standalone: true}">

      </textarea>
    </div>
    
    <div class="modal-footer flex">
        <button type="button" class="btn btn-success" (click)="sendMessageAdovcate()">Sumit</button>  
        <button type="button" class="btn btn-warning btn-close" data-bs-dismiss="modal" 
        aria-label="Close">Close</button>
    </div>
  </div>
</div>
</div>
