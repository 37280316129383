


<nav class="navbar navbar-expand-lg ml-2 mainTop" >
    <a class="navbar-brand" href="{{oldDhruva}}"><img src="assets/img/Logo.png" class="imgLogo"></a>
    <!-- <button class="btn btn-dark showToggle ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
      <i class="fa fa-bars"></i>
  </button> -->
  
    <div class="navbar-collapse ml-4 " >
      <div class="iq-search-bar textFieldTop">
        <form action="#" class="searchbox">
           <input type="text" class="text search-input searchInner" placeholder="Type here to search...">
           <a class="search-link" href="#" >
            <i class="fa-solid fa-magnifying-glass" style="color:#000"></i></a>
        </form>
     </div>
     
      <ul class="navbar-nav mr-auto  topMenu">
        <li class="nav-item active" disabled="true">
          <img src="assets/img/Settings.png" class="menuIcon"> <a disabled="true" class="nav-link" mat-menu-item href="javascipt:void(0)">Setting</a>
        </li>
        <li class="nav-item" disabled="true">
          <img src="assets/img/Calender1.png" class="menuIcon"> <a disabled="true" class="nav-link" mat-menu-item href="javascipt:void(0)">Calender</a>
        </li>
        
        <li class="nav-item" disabled="true">
          <img src="assets/img/Radiology Updates.png" class="menuIcon"> <a disabled="true" class="nav-link " mat-menu-item href="javascipt:void(0)">Lab/Radiology Updates</a>
        </li>
        <li class="nav-item" disabled="true">
          <img src="assets/img/Alerts.png" class="menuIcon"> <span ></span><a disabled="true" class="nav-link " mat-menu-item href="javascipt:void(0)">Member Alert</a>
          </li>
          <li class="nav-item">
            <img src="assets/img/Alerts.png" class="menuIcon"> <span ></span><a class="nav-link" (click)="logout()" mat-menu-item >Logout</a>
            </li>
      </ul>

      <div class="col-2 " >
        <div class="row topName">
          
        <div class="col-2">
         
          <img style="width:36px;margin-top:5px;" src="assets/img/patient.png" />
        </div>
        <div class="col-10">
          <div class="col-12 role">{{this.userDetails.firstName}}</div>
          
        </div>
        
        </div>
      </div>
  
    </div>
  </nav>

  
<!-- <a [routerLink]="['/psychologists']" mat-menu-item>
    <mat-icon>dialpad</mat-icon>
    <span>Redial</span>
</a> -->