<div class="col-md-12 flex">
    <div class="col-md-12 float">
        <div class="col-md-6 mainSleep float">
            
                <div class="col-md-12 flex">
                    <div class="col-md-3">
                        <img class="img" src={{comparedImg}}/>
                    </div>  
                    <div class="col-md-8 mt-2">
                       <div class="sleepTitle1">{{comparedHrs}} </div>

                       <div class="sleepTitle2">Compared to last period</div>
                    </div>    
                    
                </div>
                <div class="col-md-12 mt-4">
                    <highcharts-chart 
                        [Highcharts]="Highcharts"
                        [options]="chartOptions"
                        style="width: 100%; height: 140px; display: block;">
                    </highcharts-chart>
                </div>
                <div class="col-md-12 mt-2 flex">
                    <div class="col-md-6 sleepIcon">
                        <img src="/assets/img/sleepiconsmall.png"/> &nbsp; {{sleep&&sleep.sleepStartTime?sleep.sleepStartTime:'---'}}                      
                    </div>

                    <div class="col-md-6 sleepIcon floatRight" style="text-align: right;">
                        <img src="/assets/img/wakeupiconsmall.png"/> &nbsp; {{sleep&&sleep.sleepEndTime?sleep.sleepEndTime:'---'}}                    
                    </div>
                </div>
                <div class="col-md-12 mt-4">
                    <div class="col-md-6 float mt-4">
                        <p class="totalSleepDuration">Total Sleep Duration</p>
                        <p class="mt-2 sleepTime">{{totalSleepHrs}}</p>
                    </div>
                    <div class="col-md-6 float mt-4">
                        <p class="totalSleepDuration">Sleep Goal</p>
                        <p class="mt-2 sleepTime">{{totalSleepGoal}}</p>
                    </div>
                </div>

        </div>
        <div class="col-md-6 float ">
            <div class="col-md-5 float sleepSub ">
                <p><img class="img2" src="/assets/img/sleep2.png"/></p>
                <p class="sleepTimeTitle">Deep Sleep</p>
                <p class="sleepTime1">{{deepSleepTime}}</p>
                <p > <button class="btn btnClass" [style.background-color]="deepStatusColor">{{deepSleepStatus}}</button></p>
            </div>

            <div class="col-md-5 float sleepSub ml-1">
                <p><img class="img2" src="/assets/img/sleep3.png"/></p>
                <p class="sleepTimeTitle">REM Sleep</p>
                <p class="sleepTime1">{{remSleepTime}}</p>
                <p > <button class="btn btnClass" [style.background-color]="remStatusColor">{{remSleepStatus}}</button></p>
            </div>
            <div class="col-md-5 float sleepSub mt-4 ml-1">
                <p><img class="img2" src="/assets/img/sleep4.png"/></p>
                <p class="sleepTimeTitle">Light Sleep</p>
                <p class="sleepTime1">{{lightSleepTime}}</p>
                <p > <button class="btn btnClass" [style.background-color]="lightStatusColor">{{lightSleepStatus}}</button></p>
            </div>
            <div class="col-md-5 float sleepSub mt-4 ml-1">
                <p><img class="img2" src="/assets/img/sleep1.png"/></p>
                <p class="sleepTimeTitle">Awake Time</p>
                <p class="sleepTime1">{{awakeTime}}</p>
                <p > <button class="btn btnClass" [style.background-color]="awakeStatusColor">{{awakeStatus}}</button></p>
            </div>

            
        </div>
    </div>
    <!-- <div class="col-md-4 float" style="margin-top:-70px">
        <div class="noteSection">Notes Section</div>
        <app-notes></app-notes>
    </div>
     -->
    </div>