import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgFor,NgIf } from '@angular/common';
import { formatDate } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-upcoming-meeting',
  standalone: true,
  imports: [NgFor,NgIf],
  templateUrl: './upcoming-meeting.component.html',
  styleUrl: './upcoming-meeting.component.css'
})
export class UpcomingMeetingComponent {
  @Input() eventData:any;
  today= new Date();
  checkRecord:any=0;
  event:any=[];
  constructor(private datePipe: DatePipe){

  }
  getData(data:any){
    const nowTime = formatDate(this.today, ' HH:mm', 'en-US');
  // console.log(data.length);
   var time1 = nowTime.split(':');
    for(let i =0;i<data.length;i++){
   
    var time2 = data[i].eventTime.split(':');
   
    if( parseFloat(time2[0]) > parseFloat(time1[0])){
      this.event=this.eventData[i];
      this.checkRecord = i+1;
      i=100;
      
    } else if(parseFloat(time1[0]) == parseFloat(time2[0]) && parseFloat(time2[1]) > parseFloat(time1[1])) {
      this.event=this.eventData[i];
      this.checkRecord = i+1;
      i=100;
    
    }
  }
  //console.log('these'+this.checkRecord );
  return 1;
  }
  getTime(selectedTime:any){
    const timeParts = selectedTime.split(':');
    const selectedDate = new Date();
    selectedDate.setHours(parseInt(timeParts[0], 10));
    selectedDate.setMinutes(parseInt(timeParts[1], 10));
   return this.datePipe.transform(selectedDate, 'hh:mm a');
  }
}
