<!-- <div class="row">
    <img style="width:6%" src="assets/img/backbtn.png" />
</div> -->
<!-- <app-basic-details [screentype] = "2"></app-basic-details> -->

<!-- <div class="row">
    <img style="width:6%" src="assets/img/backbtn.png" />
</div>  -->
<div class="row">
    
    <img class="imgBack" *ngIf="isBackButton" (click)="backButton()" src="assets/img/backbtn.png" />
  </div>
<div id="wait" *ngIf="isLoading == 1">
    <mat-spinner ></mat-spinner>
</div>
<div *ngIf="isResult">
    <div class="row mt-5 ml-2">
        <div class="title">Result</div>
    </div>
    <div *ngFor="let meal of result;let j=index;">
<div class="row mt-5 ml-1">
    <div class="col dt">
    {{getDate(meal.date)}}, {{meal.day}}
    </div>

    <div class="col textAlign ">
        
            <div class="mainTabDiv">
                <button class="btn tabContent" data-bs-toggle="modal" 
                data-bs-target="#exampleModal" (click)="addFasting(j,getDate(meal.date),meal.day)">Add Fasting Window</button>
            </div>

            <div class="activeTab ml-1">
                <button class="btn tabContent" (click)="addMeal(j,meal.date)" style="color:#000">Add Meal</button>
            </div>
       
    </div>
</div> 


<div class="row mt-5 ">
    <div class="col-6 mt-3" *ngFor="let recipes of meal.recipes;let i=index;">
       <app-result [mealResult]="recipes" [index]="i" (viewItem)="viewDetails($event)" (newItemEvent)="checkTimeExist($event,j)" (removeRow)="removeRow($event,j)" ></app-result>
    </div> 
   
</div>
<div class="row mt-3" *ngIf="meal.fasting != undefined">
    <div class="col-6 mt-3"  *ngFor="let fasting of meal.fasting;let i=index;">
        
        <app-fasting [fastingResult]="fasting" [index]="i"  (removeFastingRow)="removeFastingRow($event,j)" ></app-fasting>
     </div> 
    </div>

</div>
   
    <!-- <div class="col-sm">
       
        <app-result></app-result>  

    </div>   -->


<div class="col-md-12 mt-5 textAlign">
    <button class="btn btnClass" (click)="saveMealPlan()">Save Meal Plan</button>
</div>
</div>



<div *ngIf="isSingle">
    <app-meal-details [details]="viewDetials" ></app-meal-details>
</div>

<div *ngIf="isSingleMeal">
        <app-single-meal [preference] = "allPrefer" [meals]="result" [indexval]="singleMealIndex" [date]="singleMealDate" (viewItem1)="resultPage($event)"></app-single-meal>
</div>

<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" 
data-bs-target="#exampleModal">
Launch demo modal
</button> -->
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria- 
labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title text-danger" id="exampleModalLabel">Fasting Window</h5>
      <button type="button" class="btn btn-warning btn-close" data-bs-dismiss="modal" 
     aria-label="Close">Close</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="errorTitle" *ngIf="timeExit">You have already meal in between selected time.</div>
        </div>
        <div class="row">
            <div class="fastingTitle">Add Fasting Window</div>
        </div>

        <div class="row mt-4">
            <div class="fastingDt">{{fastingDate}}, {{fastingDay}}</div>
        </div>

        <div class="row mt-4">
            <div class="col-sm">
                
                <input type="time" [(ngModel)]="fastingStartTime"  [ngModelOptions]="{standalone: true}" placeholder="Start Date" class="form-control fastingField"/>
            </div>
            <div class="col-sm">
                <input type="time" [(ngModel)]="fastingEndTime"  [ngModelOptions]="{standalone: true}" placeholder="End Date" class="form-control fastingField"/>
            </div>
        </div>
    </div>
    <div class="modal-footer flex">
        <button type="button" (click)="saveFasting()" class="btn btn-success" >Add Fasting</button>  
    </div>
  </div>
</div>
</div>