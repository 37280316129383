import { Injectable } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, ReplaySubject, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ControllerApiPath } from './constant';
import { jwtDecode } from "jwt-decode";
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  private apiBaseUrl = environment.apiBaseUrl;
  userRole:any =[];
  userDetails:any=[];
  @Output() getLoggedIn: EventEmitter<boolean> = new EventEmitter(false);
  constructor(private http: HttpClient) { }

  getToken() {
    const token = localStorage.getItem('token') ?? "";
   
    return token?.replace(/['"]+/g, '');
  } 
    

  createAuthorizationHeader() {
    var token=this.getToken();
    const headers = new HttpHeaders({
     // 'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      //'X-ApiAccess-Key': '87AF$C5648AE267A689D2A75CDD165~',
       'X-ApiAccess-Key': environment.apiKey
    });
      
    return headers;
   
  }

  createAuthorizatioChatAPInHeader() {
   
    const headers = new HttpHeaders({
     // 'Content-Type': 'application/json',
      'Accept': 'application/json',
    
      
    });
      
    return headers;
   
  }


  baseUrl(){
    return this.apiBaseUrl;
  }
  getloggedUserRole(){
    const decodedToken = jwtDecode<any>(localStorage.getItem('token') ?? "");
    return decodedToken.role;   
  }
  getUserById(usrId: string): Observable<any> {
    const headers = this.createAuthorizationHeader();
    let url = this.apiBaseUrl + ControllerApiPath.getUserById + '?userId=' + usrId;
    return this.http.get(url, { headers });    
   
  }

  checkLogin(): Observable<boolean> {

    const localStorageToken = localStorage.getItem('token');
    const loggedIn = (localStorageToken !== null && localStorageToken !== '');
    if (loggedIn) {
      var user = this.getLoggedInUser();
      const cookiesToken = this.getCookie('redirect-auth-token');
     
      //const userIdCookies = decodedTokenCookies.unique_name;
      if (cookiesToken !== '' && cookiesToken != null && cookiesToken != undefined) {       
        localStorage.setItem('token', cookiesToken);
      }
      const decodedToken = jwtDecode<any>(localStorage.getItem('token') ?? "");
      const userId = decodedToken.unique_name; 
      if (user != null && user != "" && user != undefined && user.id == userId) {
        return of(true);
      }
      console.log(user);
      console.log("user");      
     // const decodedToken = jwtDecode<any>(localStorage.getItem('token')??"");
     // const userId = decodedToken.unique_name;       
      return this.getUserById(userId).pipe(
        map(response => {
          if (response && response.result) {
            this.userDetails = response.result;
            this.setCurrentUser(response.result);
            return true; // Return true indicating successful login
          } else {
            return false; // Return false if no user details found
          }
        })
      );
    }

    // Check if there is a cookie token available
    const cookiesToken = this.getCookie('redirect-auth-token');
    if (cookiesToken === '') {
      // If no cookie token is available, return observable with false
      return of(false);
    }

    // Decode token to get userId
    const decodedToken = jwtDecode<any>(cookiesToken);
    const userId = decodedToken.unique_name;

    // Store the token in localStorage
    localStorage.setItem('token', cookiesToken);

    // Get user details by userId and check if the user is valid
    return this.getUserById(userId).pipe(
      map(response => {
        if (response && response.result) {
          this.userDetails = response.result;
          this.setCurrentUser(response.result);
          return true; // Return true indicating successful login
        } else {
          return false; // Return false if no user details found
        }
      })
    );
  }


  setCurrentUser(userDetails: User) {     
    const user = userDetails;          
    localStorage.setItem('dhruvaUser', JSON.stringify(userDetails));      
  }
  logout() {
    localStorage.removeItem('dhruvaUser');
   
    localStorage.removeItem('token');

    localStorage.removeItem('dhruvaUser');
    localStorage.removeItem('token');
    localStorage.removeItem('auth-token');
    localStorage.removeItem('wholeData');

    localStorage.removeItem('selectedType');
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('foodData');
    sessionStorage.clear();    
  
    this.delete_cookie("redirect-auth-token", "", -5, "/");
    this.delete_cookie("redirect-auth-token-new", "", -5, "/");
    localStorage.clear();

    this.getLoggedIn.emit(true);
  }
delete_cookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath};Domain=.completumhealth.com;SameSite=Lax;Secure;`;
    console.log("delete_cookie");
}
  getLoggedInUser() {   
      this.userDetails = localStorage.getItem('dhruvaUser');     
      this.userDetails = JSON.parse(this.userDetails);
      return this.userDetails;
  }
  getCookie(name: any): string {
    var nameEQ = name + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
      }
    }
    return "";
  }

}
