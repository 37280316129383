import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders ,HTTP_INTERCEPTORS} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';
import { Preference } from '../model/preference';
import { catchError,tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class MealPlanService {
  
  private apiBaseUrl:string;
  chatapi=environment.chatapi+'/generate-mealplan/v4';
  singleMeal=environment.chatapi+'/generate-meals';
  constructor(private http: HttpClient,private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
   }

   getMemberMealInfo(obj:any,pageNo:number,pageSize:number){
    const headers = this.sharedService.createAuthorizationHeader();
    
    const url = this.apiBaseUrl + ControllerApiPath.members+'/mealInfo?userLoggedIn='+obj.userLoggedIn+'&date='+obj.currentDate+'&pageNo='+pageNo+'&pageSize='+pageSize+'&name='+obj.name+'&filterType='+obj.filterType;
      return this.http.get(url,{ headers });
   }

    getMemberMealCount(obj:any){
    const headers = this.sharedService.createAuthorizationHeader();
    
    const url = this.apiBaseUrl + ControllerApiPath.members+'/mealCount?userLoggedIn='+obj.userLoggedIn+'&date='+obj.currentDate;
      return this.http.get(url,{ headers });
   }

   getDailyTarget(id:any){
    const headers = this.sharedService.createAuthorizationHeader();
    let user= this.sharedService.getLoggedInUser();
      
    const url = this.apiBaseUrl + ControllerApiPath.members+'/targets?userLoggedIn='+user.id+'&memberId='+id;
      return this.http.get(url,{ headers });
   }
   getPreference(id:any): Observable<any>{
    const headers = this.sharedService.createAuthorizationHeader();
    let user= this.sharedService.getLoggedInUser();
      
    const url = this.apiBaseUrl + ControllerApiPath.preferences+'?memberId='+id;
      return this.http.get<Preference>(url,{ headers }).pipe(
        map((response:Preference) => response),
        catchError((error) => {
          
          if (error.status === 404) {
            
            return of(1);
          } else {
            throw error;
          }
        }),
      )
   }
   createMealPlan(mealDetails:any){
    const headers = this.sharedService.createAuthorizatioChatAPInHeader();
    //return  this.http.post('https://chatapi-staging.completumhealth.com/generate-meal', mealDetails, { headers })
  // return  this.http.post('https://chatapi-staging.completumhealth.com/generate-mealplan', mealDetails, { headers },).pipe(timeout(60000));
 // return  this.http.post('https://chatapi-staging.completumhealth.com/generate-mealplan/v2', mealDetails, { headers }).pipe(
    return  this.http.post(this.chatapi, mealDetails, { headers }).pipe(timeout(600000),
   
   
    map((response) => response),
    catchError((error) => {
      
      if (error.status == 504) {
        
        return of(1);
      }
      else if (error.status === 500) {
        
        return of(1);
      }
      else {
        throw error;
      }
      
    }),
  )
    ;
  //return  this.http.post('https://chatapi-staging.completumhealth.com/generate-mealplan/v3', mealDetails, { headers });
  
   }  

   saveMealPlan(mealDetails:any){
    const headers = this.sharedService.createAuthorizationHeader();
   
    return  this.http.post(this.apiBaseUrl + ControllerApiPath.mealPlan, mealDetails, { headers }).pipe(
      map((response) => response),
      catchError((error) => {
        
        if (error.status === 409) {
          
          return of(1);
        }
        else if (error.status === 500) {
          
          return of(1);
        }
        else if (error.status === 504) {
          
          return of(1);
        }
        else {
          throw error;
        }
        
      }),
    );  
   
   }

   getMemberDetails(id:any){
      const headers = this.sharedService.createAuthorizationHeader();
      const url = this.apiBaseUrl + ControllerApiPath.members+'/GetMemberById?memberId='+id;
      return this.http.get(url,{ headers });
   }

   addPreference(data:any){
    const headers = this.sharedService.createAuthorizationHeader();   
    const url = this.apiBaseUrl + ControllerApiPath.preferences;
    return  this.http.post(url, data, { headers });
   }

   getMeals(id:any){
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.mealPlan+'?memberId='+id;
    return this.http.get(url,{ headers });
   }

   getMealDetail(id:any){
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.mealPlan+'/recipes?recipeId='+id;
    return this.http.get(url,{ headers });
   }

   getSingleMeal(detail:any){
    const headers = this.sharedService.createAuthorizatioChatAPInHeader();   
    //return  this.http.post(' https://chatapi-staging.completumhealth.com/generate-meals', detail, { headers }).pipe(
      return  this.http.post(this.singleMeal,detail, { headers }).pipe(
       
      map((response) => response),
      catchError((error) => {
        
        if (error.status === 504) {
          
          return of(1);
        }
        else if (error.status === 500) {
          
          return of(1);
        }
        else {
          throw error;
        }
        
      }),
    );  
   }

   getVital(id:any){
    const headers = this.sharedService.createAuthorizationHeader();    
    const url = this.apiBaseUrl + ControllerApiPath.vital+'?memberId='+id+'&date=0001-01-01T00:00:00';
    return this.http.get(url,{ headers });
   }

   checkIsPlanAssign(id:any,fromDt:any,toDt:any){
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.mealPlan+'/checkifongoing?memberId='+id+'&fromDate='+fromDt+'&toDate='+toDt;
    return this.http.get(url,{ headers });
   }
}
