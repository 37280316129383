import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { PreferenceComponent } from '../preference/preference.component';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { formatDate } from '@angular/common';
import { MealPlanService } from '../meal-plan.service';
import { Output, EventEmitter } from '@angular/core';
import { ResultMealPlanComponent } from '../result-meal-plan/result-meal-plan.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-single-meal',
  standalone: true,
  imports: [MatProgressSpinnerModule,PreferenceComponent,FormsModule,NgClass,NgFor,NgIf,ResultMealPlanComponent],
  templateUrl: './single-meal.component.html',
  styleUrl: './single-meal.component.css'
})
export class SingleMealComponent {
  @Input() preference:any;
  @Input() meals:any;
  @Input() indexval:any;
  @Input() date:any;
  @Output() viewItem1 = new EventEmitter<string>();
  iscreateSingleMeal:boolean=true;
  isBreakfast:any=1;
  isLoading:any=2;
  isLunch:any=2;
  isDinner:any=2;
  isSnack:any=2;

  isBreakCross:any=1;
  isLunchCross:any=2;
  isDinnerCross:any=2;
  isSnackCross:any=2;

  cal:any='';
  protien:any='';
  fat:any='';
  carbs:any='';
  type:any=1;

  macros:any=[];
  mealResult:any=[];
  isResultSingle:boolean=false;;
  selectedRecipe:any;
  selectedRecp:any=500;
  checkSelectedMealType:any=[0,1,2,2,2];
  //showResult:boolean=false;
  //showSearch:boolean=true;
  constructor(private mealService:MealPlanService,private toast:ToastService) {
      
  }


  removeMealType(type:any,index:any){
    //console.log('fg');
    this.checkSelectedMealType[1] = 2;
    this.checkSelectedMealType[2] = 2;
    this.checkSelectedMealType[3] = 2;
    this.checkSelectedMealType[4] = 2;
    //console.log(this.checkSelectedMealType);
    //this.checkSelectedMealType[type] = 2;
    this.showHideMeal(type,2,1);
}

addMealType(type:any,index:any){
 
  this.checkSelectedMealType[type] = 1;
  this.showHideMeal(type,1,2);
}

showHideMeal(type:any,val:any,index:any){
  this.isBreakfast = 2;
  this.isBreakCross = 2;
  this.isLunch = 2;
  this.isLunchCross = 2;
  this.isDinner =2;
  this.isDinnerCross =2;
  this.isSnack =2;
  this.isSnackCross =2;
  this.checkSelectedMealType[1] = 2;
  this.checkSelectedMealType[2] = 2;
  this.checkSelectedMealType[3] = 2;
  this.checkSelectedMealType[4] = 2;
  this.checkSelectedMealType[type] = 1;
  if(index == 2){
    this.type = type;
  }
  else {
    this.type = 0;
  }
  if(type == 1){
    this.isBreakfast =val;
    this.isBreakCross =val;
}
else if(type == 2){
  this.isLunch = val;
  this.isLunchCross = val;
}
else  if(type == 3){
  this.isDinner =val;
  this.isDinnerCross =val;
}
else if(type == 4){
this.isSnack =val;
this.isSnackCross =val;
}
}
  createMealPlan(){
    this.macros = [];
    let mealType =['','Breakfast','Lunch','Dinner','Snack'];
    if(this.type < 1){
      this.toast.errorMessage('Please add atleast meal.');
    }
    else if(this.cal == '' || this.protien == '' || this.fat == '' || this.carbs == ''){
      this.toast.errorMessage('Please add all macros.');
    }
    else if(this.cal < 1 || this.protien < 1 || this.fat < 1 || this.carbs < 1){
      this.toast.errorMessage('Please add all macros.');
    }
    else{
    this.macros.push({percentage:0,type:mealType[this.type],calories:this.cal,macroBreakdown:{protein:this.protien,carbs:this.carbs,fat:this.fat},prepTime:10,time:''});
    let mealDetail = {calsNeeded:0,planStartDate:this.dateFormat(this.date),planEndDate:this.dateFormat(this.date),meals:this.macros,cuisinePreference:{default:this.preference.cuisine.defaultCuisines,liked:this.preference.cuisine.likedCuisines,disliked:this.preference.cuisine.dislikedCuisines,foodRestrictions:this.preference.cuisine.foodRestrictions,allergies:this.preference.allergies,ingredientPreferences:{liked:this.preference.likedIngredients,disliked:this.preference.dislikedIngredients,lifeStyle:{isKidFriendly:this.preference.lifeStyle.isKidFriendly,isMealPlanningFriendly:this.preference.lifeStyle.isMealPlanningFriendly}}}};
     //console.log(mealDetail);
    this.isLoading = 1;
     this.mealService.getSingleMeal(mealDetail).subscribe(response => { 
      this.isLoading = 2; 
      if(response == 1){
        this.toast.errorMessage('Api through error.Please try again.');
      }else{
      this.mealResult = response;
      this.mealResult = this.mealResult.meals;
     // console.log('here',this.mealResult); 
      this.isResultSingle = true;
      this.iscreateSingleMeal = false; 
      
      this.toast.successMessage('Recipes fetch successfully');
      //this.meals[this.indexval].recipes=this.mealResult;
      }
  });
  }
  }

  chooseRecipe(index:any){
    this.selectedRecipe = index;
    this.selectedRecp = index;
    //this.meals[this.indexval].recipes.push(this.mealResult[index]);
  }

  backBtn1(){
  //  this.isResult = true;
    this.iscreateSingleMeal = false;  
  }


  backBtn(){
    this.isResultSingle = false;
    this.iscreateSingleMeal = true;  
  }

  dateFormat(date:any){
    return formatDate(date, 'yyyy-MM-dd','en-US');
  }

  addRecipe(){
    this.meals[this.indexval].recipes.push(this.mealResult[this.selectedRecipe]);
    //console.log(this.meals[this.indexval].recipes);
    this.toast.successMessage('Recipes added successfully');
    this.viewItem1.emit('1');
  }

  getType(unit:any,size:any,qty:any){
      let title:any;
     // console.log(unit);
      if(unit === 'grams'){
        title = size+' '+unit;
      }
      else if(unit === 'gram'){
        title = size+' '+unit;
      }else{
        title = qty+' '+unit;
      }
      return title;
  }
}
