import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgFor } from '@angular/common';
import {CdkAccordionModule} from '@angular/cdk/accordion';

@Component({
  selector: 'app-health',
  standalone: true,
  imports: [NgFor,CdkAccordionModule],
  templateUrl: './health.component.html',
  styleUrl: './health.component.css'
})
export class HealthComponent {
@Input() heathQns:any;
plainText:any=[];
mutliple:any=[];
singleOption:any=[];
multiText:any=[];
ngOnInit(): void {
  this.plainText = this.heathQns.filter((questions:any) => questions.dataType === "plainText");
  this.mutliple = this.heathQns.filter((questions:any) => questions.dataType === "multiSelect");
  this.singleOption = this.heathQns.filter((questions:any) => questions.dataType === "MultiSelectWithKeys");
}
}
