<div class="row flex" *ngIf="screentype == 1">
    <div class="col-lg-2"><img class="patientImg" mat-card-image src={{imgSrc}}/></div>
    <div [ngClass]="selectedTab=='tab2'? 'col-lg-4 name mt-1' : 'col-lg-7 name mt-1'">
      <div class="name">
         {{memberMealDetails.name}}
      </div>
      <div class="age" >
        Age: {{memberMealDetails.age}}
    </div>
    </div>
    <div [ngClass]="selectedTab=='tab2'? 'col-md-6' : 'col-md-3 flex'">
      <!-- <div class="col-md-3" *ngIf="selectedTab!='tab2'"><img src="assets/img/editBtn.png" /></div>  -->
        <!-- <div class="col-md-3 mt-1"  *ngIf="selectedTab!='tab2'">Edit</div>  -->
        <div>
        <!-- <button class="btn btnFirst btnLabel" *ngIf="selectedTab=='tab2'">Create Meal Plan</button> -->
        <a class="btn btnFirst btnLabel" [routerLink]="['/create-plan']" [queryParams]="{ memberId:memberMealDetails.memberId}" *ngIf="selectedTab=='tab2'" href="#" >Create Meal Plan</a>
    </div>

</div>

