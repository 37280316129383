import { Component, Input,inject, model, signal  } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MemberBasicDetailComponent } from './member-basic-detail/member-basic-detail.component';
import { BloodPressureComponent } from './blood-pressure/blood-pressure.component';
import { RouterLink } from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { DashboardService } from '../../dashboard/dashboard.service';
import { ToastService } from '../../services/toast.service';
import { FormsModule } from '@angular/forms';

export interface DialogData {
  id: string;

}

import { NgIf } from '@angular/common';

@Component({
  selector: 'app-member-card',
  standalone: true,
  imports: [MatCardModule,MemberBasicDetailComponent,BloodPressureComponent,RouterLink,NgIf,FormsModule],
  templateUrl: './member-card.component.html',
  styleUrl: './member-card.component.css'
})
export class MemberCardComponent {
  @Input() memberdata:any;
  @Input() index:any;
  isHide:any=1;
  memberDt:any=[];
  isModal=1;
  isClose=1;
  details:any={
    memberId:'',
    memberAdvocateId:'',
    message:''
  };
  // readonly animal = signal('');
  // readonly name = model('');
  readonly dialog = inject(MatDialog);
  public dialogRef: MatDialogRef<DialogboxComponent>;
  constructor(private dashboard:DashboardService,private toast:ToastService) { }
  ngOnInit(): void {
  
    this.memberDt = this.memberdata;
    this.details.memberAdvocateId = this.memberDt.memberAdvocateId;
    //console.log('here',this.memberDt);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogboxComponent, {
      data: {id: this.memberdata.id},
    });

    dialogRef.afterClosed().subscribe(result => {
     // console.log('The dialog was closed');
   
     this.isClose  =1;
      if (result === undefined) {
        this.isHide = 2;
      }
    });
  }
  onNoClick(): void {
   // console.log('dsd');
    this.isClose = 2;
    this.dialogRef.close();
  }

  openPopup(data:any){
    //console.log(data);
    this.memberDt = data;
    this.isModal=2;
   this.details.memberId=this.memberDt.id;
   // console.log('here',this.memberDt.personName);
  }

  sendMessageAdovcate(){
    //console.log(this.details);
    if(this.details.message == ''){
      this.toast.errorMessage('Message field is required.');
    }else{
      this.dashboard.advocateMessage(this.details).subscribe(response =>{
       let data:any = response;
        if(data.isSent === true){
          this.isClose = 2;
          setTimeout(()=>{                           // <<<---using ()=> syntax
            this.isClose = 1;
        }, 3000);
        this.details.message = '';
          this.toast.successMessage('Message sent successfull.');
        }else{
          this.toast.errorMessage('Message not sent.');
        }
      });
    }
  
  }
}



@Component({
  selector: 'dialogbox',
  templateUrl: './dialogbox/dialogbox.component.html',
  styleUrl: './dialogbox/dialogbox.component.css',
  standalone: true,
  imports: [ MatDialogModule],
  
})

export class DialogboxComponent {
  readonly dialogRef = inject(MatDialogRef<DialogboxComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  readonly id = model(this.data.id);
  
  constructor(private dashboard:DashboardService,private toast:ToastService) { }
  markResolved(){
    this.dashboard.markResolve(this.data.id).subscribe(response => { 
     this.toast.successMessage('Resolved successfully');
     this.dialogRef.close();    
     return 2;
  });
}


}