
<div class="row memberPartern fixHeight flex">


<mat-card class="col  mt-5">
    <mat-card-header>
      <mat-card-subtitle><img mat-card-sm-image style="width: 67px;height:60px;margin-left:-11px;" src="/assets/img/personicon.png"/></mat-card-subtitle>
      
    </mat-card-header>
    <mat-card-content>
     <div class="row ">
        <div class="col-5">
            <div class="memberCount1">{{totalCount.totalActiveMembers}}</div>
        </div>
        <div class="col-7 memberCount mt-3">
            Total<br/>Members
        </div>
     </div>
     
    </mat-card-content>
    <mat-card-actions>
        &nbsp;<a [routerLink]="['/members']"  mat-stroked-button class="btn" >View All Members</a>
    </mat-card-actions>
    <mat-card-footer>
     
    </mat-card-footer>
  </mat-card>

  <mat-card class="col  ml-3 mt-5">
    <mat-card-header>
      <mat-card-subtitle><img mat-card-sm-image style="width: 67px;height:60px;margin-left:-11px;"  src="/assets/img/personsicon.png"/></mat-card-subtitle>
      
    </mat-card-header>
    <mat-card-content>
     <div class="row flex ">
        <div class="col-5">
            <div class="memberCount1">{{totalCount.totalActiveTeams}}</div>
        </div>
        <div class="col-7 memberCount mt-3">
            Total<br/>Teams
        </div>
     </div>
     
    </mat-card-content>
    <mat-card-actions>
        &nbsp;&nbsp;<a [routerLink]="['/teams']"  mat-stroked-button class="btn" >View All Teams</a>&nbsp;
    </mat-card-actions>
    <mat-card-footer>
     
    </mat-card-footer>
  </mat-card>
</div>