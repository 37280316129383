import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';
import { Observable,ReplaySubject } from 'rxjs';
import { userIdentity } from '../models/userIdentity';
import { LoginModel } from '../models/login';
import { User } from '../models/user';
import { catchError } from 'rxjs/operators';
//import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiBaseUrl:string;
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();
  constructor(private http: HttpClient,private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
   }
  

  login(payload: LoginModel):Observable<any> {    
    const headers = this.sharedService.createAuthorizationHeader();
    let url = this.apiBaseUrl + ControllerApiPath.login+'?username='+payload.username+'&password='+payload.password;
    return this.http.get(url,{ headers });   
  }

  setCurrentUser(userIdentity: userIdentity) {
    console.log(userIdentity);
    this.delete_cookie("redirect-auth-token-new", "", -5, "/")
    this.delete_cookie("redirect-auth-token", "", -5, "/")
   // debugger;
    const user = userIdentity.userDetails;
    const userRoles = user.userRoles;
    const roles = this.getDecodedToken(userIdentity.token).role;
    
   // Array.isArray(roles) ? user.UserRoles = roles : user.UserRoles.push(roles);   
    localStorage.setItem('token', JSON.stringify(userIdentity.token));
    localStorage.setItem('dhruvaUser', JSON.stringify(user));
    this.setCookie("redirect-auth-token-new", JSON.stringify(userIdentity.token), 1, "/")
    var coVal = this.getCookie("redirect-auth-token-new");    
    localStorage.setItem('auth-token', coVal);
    this.currentUserSource.next(user);
  }
  getDecodedToken(token: string) {
    return JSON.parse(atob(token.split('.')[1]));
  }
  setCookie(name: string, value: string, expireDays: number, path: string = '') {
    console.log("redirect-auth-token");
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath};Domain=.completumhealth.com;SameSite=Lax;Secure;`;
    var coVal = this.getCookie("redirect-auth-token");
  }
  delete_cookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath};Domain=.completumhealth.com;SameSite=Lax;Secure;`;
    console.log("delete_cookie");
  }
  getCookie(name: any): string {
    var nameEQ = name + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
      }
    }
    return "";
  }
  checkEmailId(email:any){
    
    const headers = this.sharedService.createAuthorizationHeader();
    return  this.http.post<userIdentity>(this.apiBaseUrl + ControllerApiPath.identity+'/CheckEmailId?email='+email,'',{ headers }).pipe(
      map((userIdentity: userIdentity) => {
        if (userIdentity) {
        //  this.toastr.success("Login Successfull");
          this.setCurrentUser(userIdentity);

        }
      })
    );
  }
}
