import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgIf,CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
@Component({
  selector: 'app-basic-details',
  standalone: true,
  imports: [NgIf,CommonModule,RouterLink],
  templateUrl: './basic-details.component.html',
  styleUrl: './basic-details.component.css'
})
export class BasicDetailsComponent {
@Input() screentype:any;
@Input() memberMealDetails:any;
@Input() selectedTab:any;
@Input() mealMember:any;
imgSrc:any = "";
ngOnInit(): void {
 console.log(this.mealMember);
  // if(this.memberMealDetails.profilePicture != undefined){
  //   this.imgSrc = this.memberMealDetails.profilePicture;
  // }
}
}
