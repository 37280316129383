<div class="meal">
    <div class="row flex">
        <div class="col mealType">
            {{mealResult.type}}
        </div>
        <div class="col" style="text-align: right;">
            <img style="width:10%" (click)="removeEntry(index)" src="/assets/img/cross1.png" />
        </div>
    </div>
    <div class="row flex mt-4">
        <!-- <div class="col-2">
            <img src="/assets/img/mealpic.png"/>
        </div> -->
        <div class="col">
            <div class="col-md-12 ml-4">
                <div class="mealName">{{mealResult.name}}, {{getType(mealResult.mealPlanServing.unit,mealResult.mealPlanServing.size,mealResult.mealPlanServing.quantity)}}
                    <!-- <span *ngIf="mealResult.mealPlanServing.unit === 'grams' ">{{mealResult.mealPlanServing.size}} {{mealResult.mealPlanServing.unit}}</span>
                    <span *ngIf="mealResult.mealPlanServing.unit !== 'grams'">{{mealResult.mealPlanServing.quantity}} {{mealResult.mealPlanServing.unit}}</span>
                    <span *ngIf="mealResult.mealPlanServing.unit === 'gram'">{{mealResult.mealPlanServing.size}} {{mealResult.mealPlanServing.unit}}</span>
                    <span *ngIf=" mealResult.mealPlanServing.unit !== 'gram'">{{mealResult.mealPlanServing.quantity}} {{mealResult.mealPlanServing.unit}}</span> -->
                </div>
            </div>
            <div class="row mt-5 ">
                <div class="col-sm">
                    <div class="col-md-12 flex">
                        <img class="imgClass" src="/assets/img/Ellipse 137.png"/> &nbsp;
                        <div class="nutriTitle mt-1">Calories</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        {{mealResult.nutritionBreakdown.calories.toFixed(2)}}
                    </div>
                    <div class="col-md-12 mt-2 unit ml-4">
                        cal
                    </div>
                </div>
                <div class="col-sm">
                    <div class="col-md-12 flex">
                        <img class="imgClass" src="/assets/img/Ellipse 138.png"/> &nbsp;
                        <div class="nutriTitle mt-1">Protien</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        {{mealResult.nutritionBreakdown.protein.toFixed(2)}}
                    </div>
                    <div class="col-md-12 mt-2 unit ml-4">
                        grams
                    </div>
                </div>

                <div class="col-sm">
                    <div class="col-md-12 flex">
                        <img class="imgClass" src="/assets/img/Ellipse 139.png"/> &nbsp;
                        <div class="nutriTitle mt-1">Carbs</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        {{mealResult.nutritionBreakdown.carbs.toFixed(2)}}
                    </div>
                    <div class="col-md-12 mt-2 unit ml-4">
                        grams
                    </div>
                </div>
                <div class="col-sm">
                    <div class="col-md-12 flex">
                        <img class="imgClass" src="/assets/img/140.png"/> &nbsp;
                        <div class="nutriTitle mt-1">Fat</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-3">
                        {{mealResult.nutritionBreakdown.fat.toFixed(2)}}
                    </div>
                    <div class="col-md-12 mt-2 unit ml-3">
                        grams
                    </div>
                </div>
                
                </div>

                
        </div>
    </div>
    <div class="col-md-12 textAlign mt-4 flex">
        <input matInput  (blur)="addTime($event)"  value={{mealResult.time}} [(ngModel)]="mealResult.time" [ngModelOptions]="{standalone: true}" class="form-control textTime" placeholder="HH:MM" type="time-24" ampm={false}  [ngxTimepicker]="picker" [format]="24" placeholder="HH:MM" >
                    <ngx-material-timepicker #picker [format]="24"></ngx-material-timepicker>
         <!-- <input type="time" (change)="addTime($event)" value={{mealResult.time}} class="form-control textTime"/> -->
          &nbsp;&nbsp; <button class="btn btnClass"   (click)="viewMealPlan(mealResult)" >View More Details</button> 
        </div>
   
</div>