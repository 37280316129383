import { Component } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';

import { RouterModule } from '@angular/router';
import { SharedServiceService } from '../services/shared-service.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-top-nav',
  standalone: true,
  imports: [MatMenuModule,MatIconModule,RouterModule],
  templateUrl: './top-nav.component.html',
  styleUrl: './top-nav.component.css'
})
export class TopNavComponent {
  userDetails:any=[];
  oldDhruva = environment.oldDhruva;
  constructor(private sharedService:SharedServiceService){

  }

  ngOnInit(): void {
    //console.log('TopNavComponent');        
    this.sharedService.checkLogin().subscribe({
      next: (isLoggedIn: boolean) => {
        this.userDetails = this.sharedService.getLoggedInUser();        
        //console.log('User is logged in:', isLoggedIn);  
        if( isLoggedIn === false)   {
          window.location.href="/";
        }   
      },
      error: (error) => {
        console.error('Error occurred during login check:', error);         
      }
    });
  }
    
   
  
  logout() {
    this.sharedService.logout();   
    // sessionStorage.clear();    
  
    // localStorage.removeItem('dhruvaUser');
    // localStorage.removeItem('token');

    // localStorage.removeItem('dhruvaUser');
    // localStorage.removeItem('token');
    // localStorage.removeItem('auth-token');
    // localStorage.removeItem('wholeData');

    // localStorage.removeItem('selectedType');
    // localStorage.removeItem('startDate');
    // localStorage.removeItem('endDate');
    // localStorage.removeItem('foodData');

    // localStorage.clear();
    window.location.href="/";
 
  }
}
