import { Component} from '@angular/core';
import { UpcomingMeetingComponent } from './upcoming-meeting/upcoming-meeting.component';
import { CriticalPatientsComponent } from '../member/critical-patients/critical-patients.component';
import { MemberStatisticsComponent } from './member-statistics/member-statistics.component';
import { LabTestComponent } from './lab-test/lab-test.component';
import { DashboardService } from './dashboard.service';
import { NgFor } from '@angular/common';
import { NgClass } from '@angular/common';
import { NgIf } from '@angular/common';

import { 
  formatDate 
 } 
  from '@angular/common';
import { SharedServiceService } from '../services/shared-service.service';
  

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [NgIf,UpcomingMeetingComponent,CriticalPatientsComponent,MemberStatisticsComponent,LabTestComponent,NgFor,NgClass],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})


export class DashboardComponent {
  memberList:any=[];
  basicDetails:any=[];
  members:any=[];
  events:any=[];
  userDetails:any=[];
  currentDate: any = formatDate(Date.now(), 'yyyy-MM-dd', 'en-US');
  constructor(private dashboard: DashboardService, private sharedService: SharedServiceService) {
  }
  ngOnInit(): void {
   // console.log("DashboardComponent");    
    this.sharedService.checkLogin().subscribe({
      next: (isLoggedIn: boolean) => {
        this.getCriticalRiskData();
        this.getEvent(this.currentDate);
        this.userDetails = this.dashboard.getLoggedUser();       
        // /console.log('User is logged in:', isLoggedIn);
      },
      error: (error) => {
        console.error('Error occurred during login check:', error);
        
      }
    });          
  }
  



  public getEvent(date:any){
    this.dashboard.getEvents(date).subscribe(response => { 
        this.events= response;
        this.events = this.events.result;
        //console.log(this.events);
    });
  }

  getCriticalRiskData(){
    this.dashboard.getCriticalRisk().subscribe(response => {    
     this.memberList = response;
     this.memberList = this.memberList.result;
     //console.log(this.memberList);
      //console.log(this.memberList);
      let k=0;
      let m=0;
      this.members.push([]);
      for(let j=0;j<this.memberList.length;j++){
        
          if(m > 2){
            this.members.push([]);
            m=1;
            k++;
            
          }else{
            m++;
          }
          this.members[k].push({'personName':this.memberList[j].firstName+' '+this.memberList[j].lastName,'age':this.memberList[j].age,'blood':0,'glucose':this.memberList[j].sugerLevel,'meet':'https:google.com','id':this.memberList[j].memberId,'memberAdvocateId':this.memberList[j].memberAdvocateId,'memberAdvocateName':this.memberList[j].memberAdvocateName});
      }
      //console.log(this.members);
    });
  }
  dateFormat(date:any){
    return formatDate(date, 'yyyy-MM-dd','en-US');
  }
  

}
