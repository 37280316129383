<div id="wait" *ngIf="isLoading == 1">
  <mat-spinner ></mat-spinner>
</div>
<div class="col-md-12">
    
    <a [routerLink]="['/member-details']" [queryParams]="{ id:memberId,islist:2}" href="#"><img style="width:6%" src="assets/img/backbtn.png" /></a>
</div>

<div class="col-md-12 mt-4 flex">
    <div class="col-md-2">
        <img  class="imgClass" src="{{foodDetails.foodImageUrl}}"/>
    </div>
    <div class="col-md-10">
        <div class="col-md-12 title">{{foodDetails.name}}</div>

        <div class="col-md-6 mt-3">
            
            <mat-list class="foodDetails" style="display:flex;">  
                <mat-list-item class="foodDetails borderRight">  {{foodDetails.logTime.split('T')[0]}} </mat-list-item>  
                <mat-list-item class=" foodDetails borderRight"> {{getDate(foodDetails.logTime.split('T')[1].split('.')[0])}}  </mat-list-item>  
                <!-- <mat-list-item class="foodDetails borderRight"> {{foodDetails.serving.quantity}} serving  </mat-list-item>   -->
               </mat-list>  
        </div>

       <app-total-nutri [foodInfo]="foodTotalCal"></app-total-nutri>

       <div class="col-md-12 mt-5">
        <form #f="ngForm" (ngSubmit)="updateFoodLog()">
   
            <div class="col-lg-12 form-group float ">
               
                <label for="exampleInputEmail1" class="form-label labels">Notes:</label>
          
                <textarea class="form-control fieldClass" [(ngModel)]="foodDetails.notes"  [ngModelOptions]="{standalone: true}" row="5" matInput>{{foodDetails.notes}}</textarea>
               
         
        </div>
            <!-- <div class="col-lg-6 form-group float mt-3">
               
                    <label for="exampleInputEmail1" class="form-label labels">Number of Servings</label>
              
                    <input matInput (keyup)="changeServingNo($event)" [(ngModel)]="foodDetails.serving.quantity"  [ngModelOptions]="{standalone: true}" type="number" placeholder="Type Here..." class="form-control inputField fieldClass" id="exampleInputEmail1" aria-describedby="emailHelp">
                   
             
            </div> -->

            <div class="col-lg-6 form-group float mt-3">
               
                <label for="exampleInputEmail1"  class="form-label labels">Meal Type</label>
          
               <select [(ngModel)]="foodDetails.mealType"  [ngModelOptions]="{standalone: true}" class="form-control fieldClass">
                <option value="Breakfast">Breakfast</option>
                <option value="Lunch">Lunch</option>
                <option value="Snack">Snack</option>
                <option value="Dinner">Dinner</option>
               </select>
        </div>

        
    <!-- <div class="col-lg-6 form-group float mt-3">
               
        <label for="exampleInputEmail1" class="form-label labels">Serving Size</label>
  
        <input matInput type="text" (keyup)="changeServingSize($event)" [(ngModel)]="foodDetails.serving.size"  [ngModelOptions]="{standalone: true}"  placeholder="Type Here..." class="form-control inputField fieldClass" />
    </div> -->
    <!-- <div class="col-lg-6 form-group float mt-3">
                
        <label for="exampleInputEmail1" class="form-label labels">Serving Unit</label>

        <input matInput type="text" [(ngModel)]="foodDetails.serving.unit"  [ngModelOptions]="{standalone: true}"  placeholder="Type Here..." class="form-control inputField fieldClass" id="exampleInputEmail1" aria-describedby="emailHelp">
    
    </div> -->

    <!-- <div class="col-lg-6 form-group float mt-3">
                
        <label for="exampleInputEmail1" class="form-label labels">Date</label>

        <input matInput value="{{foodDetails.logTime.split('T')[0]}} " disabled type="text" placeholder="Type Here..." class="form-control inputField fieldClass" id="exampleInputEmail1" aria-describedby="emailHelp">

    </div> -->

    <div class="col-lg-6 form-group float mt-3">
                
        <label for="exampleInputEmail1" class="form-label labels">Time</label>

        <input matInput value="{{getDate(foodDetails.logTime.split('T')[1].split('.')[0])}}" disabled type="text" placeholder="Type Here..." class="form-control inputField fieldClass" id="exampleInputEmail1" aria-describedby="emailHelp">
    

    </div>


            </form>
       </div>
    </div>
</div>

<h2>Overall Ingredients</h2>

<table mat-table [dataSource]="dataSource">

   
    <!-- Name Column -->
    <ng-container matColumnDef="Ingredients">
      <th mat-header-cell *matHeaderCellDef> Ingredients </th>
      <td mat-cell *matCellDef="let element;let i = index;" > {{element.name}}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="Number of Servings">
      <th mat-header-cell *matHeaderCellDef> Number of Servings </th>
      <td mat-cell *matCellDef="let element;let i = index;" ><input type="number" (change)="changeNoServing($event,i,1)"  class="form-control textField" value="{{element.serving.quantity}}" /> </td>
      <!-- <td mat-cell *matCellDef="let element;let i = index;" ><input type="number" (change)="changeNoServing($event,i,1)"  class="form-control textField" value="{{element.serving.quantity}}" /> </td> -->
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="Serving Unit">
      <th mat-header-cell *matHeaderCellDef> Serving Unit </th>
      <td mat-cell *matCellDef="let element;let i=index">
        <select mat-select class="form-control"(change)="changeNoServing($event,i,3)"  [(ngModel)]="element.serving.unit"  [ngModelOptions]="{standalone: true}">
            <option value="grams">Grams</option>
            <option value="ounces">ounces </option>
            <option value="cups">Cups </option>
        </select>
      </td>
    </ng-container>


    <ng-container matColumnDef="Serving Size">
      <th mat-header-cell *matHeaderCellDef> Serving Size </th>
      <td mat-cell *matCellDef="let element;let i = index;"> <input type="number" (change)="changeNoServing($event,i,2)"  class="form-control textField" value="{{element.serving.size}}" /></td>
    </ng-container>

    <ng-container matColumnDef="Proteins">=
      <th mat-header-cell *matHeaderCellDef> Proteins </th>
      <td mat-cell *matCellDef="let element"> {{element.nutrition.protein}}</td>
    </ng-container>

    <ng-container matColumnDef="Carbs">
      <th mat-header-cell *matHeaderCellDef> Carbs </th>
      <td mat-cell *matCellDef="let element"> {{element.nutrition.carbs}}</td>
    </ng-container>

   

    <ng-container matColumnDef="Fats">
      <th mat-header-cell *matHeaderCellDef> Fats </th>
      <td mat-cell *matCellDef="let element"> {{element.nutrition.fat}}</td>
    </ng-container>

   

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
  </table>
 

  <div class="col-md-3 floatRight"> <button type="button" (click)="updateFoodLog()" class="btn btnClass">Update</button></div>