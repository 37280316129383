import { Component } from '@angular/core';
import { Input } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'app-eating',
  standalone: true,
  imports: [MatProgressBarModule],
  templateUrl: './eating.component.html',
  styleUrl: './eating.component.css'
})
export class EatingComponent {
@Input() eat:any;
}
