<div class="col-md-12 flex mt-3">
    <div class="col">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/nutri1.png"/>
            </div>
            <div class="col-md-12 mt-3 gridTitle">
                <div><span>Calories Percentage</span><span style="margin-left: 56px;">{{nutri && nutri.calories && nutri.calories.percentage?nutri.calories.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{nutri && nutri.calories && nutri.calories.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Calories taken: {{nutri && nutri.calories && nutri.calories.average?nutri.calories.average +" cal":'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{nutri && nutri.calories && nutri.calories.goal?nutri.calories.goal+" cal":'---'}}</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/nutri2.png"/>
            </div>
             <div class="col-md-12 mt-3 gridTitle">
                <div><span>Protein Percentage</span><span style="margin-left: 56px;">{{nutri && nutri.protein && nutri.protein.percentage?nutri.protein.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{nutri && nutri.protein && nutri.protein.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Protein taken: {{nutri && nutri.protein && nutri.protein.average?nutri.protein.average +' cal':'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{nutri && nutri.protein && nutri.protein.goal?nutri.protein.goal+' cal':'---'}}</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/nutri3.png"/>
            </div>
             <div class="col-md-12 mt-3 gridTitle">
                <div><span>Carbs Percentage</span><span style="margin-left: 56px;">{{nutri && nutri.carbs && nutri.carbs.percentage?nutri.carbs.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{nutri && nutri.carbs && nutri.carbs.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Carbs taken: {{nutri && nutri.carbs && nutri.carbs.average?nutri.carbs.average +' cal':'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{nutri && nutri.carbs && nutri.carbs.goal?nutri.carbs.goal+' cal':'---'}}</div>
            </div>
        </div>
    </div>
     <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/nutri4.png" height="35" width="35"/>
            </div>
             <div class="col-md-12 mt-3 gridTitle">
                <div><span>Fats Percentage</span>&nbsp;&nbsp;<span style="margin-left: 56px;">{{nutri && nutri.fats && nutri.fats.percentage?nutri.fats.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{nutri && nutri.fats && nutri.fats.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Fats taken: {{nutri && nutri.fats && nutri.fats.average?nutri.fats.average +' cal':'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{nutri && nutri.fats && nutri.fats.goal?nutri.fats.goal+' cal':'---'}}</div>
            </div>
        </div>
    </div>
    </div>