<div class="meal">
    <div class="row flex">
        <div class="col mealType">
            Fasting
        </div>
        <div class="col" style="text-align: right;">
            <img style="width:10%"  (click)="removeFastingEntry(index)" src="/assets/img/cross1.png" />
        </div>
    </div>
    <div class="row flex mt-4">
        <div class="col-2">
            <img src="/assets/img/fastingIcon.png"/>
        </div>
        <div class="col-9 ml-1">
            <div class="row ">
                <div class="col-sm">
                    <div class="col-md-12 flex">
                      
                        <div class="nutriTitle mt-2">Start Time</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        {{fastingResult.fromTime}}
                    </div>
                    
                </div>
                <div class="col-sm">
                    <div class="col-md-12 flex">
                        
                        <div class="nutriTitle mt-2">End Time</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        {{fastingResult.toTime}}
                    </div>
                   
                </div>

                <div class="col-sm">
                    <div class="col-md-12 flex">
                       
                        <div class="nutriTitle mt-2">Duration</div>
                    </div>
                    <div class="col-md-12 mt-2 val ml-4">
                        {{fastingResult.duration}}h
                    </div>
                 
                </div>
             
                
                </div>
        </div>
    </div>