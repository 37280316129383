<div class="col-md-8 flex mt-5">
    <div class="col">
        <div class="gridSub">
            <div class="col-md-12">
                <img style="width: 25%;" src="/assets/img/memberdashboard/glus2.png"/> 
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Average Fasting Glucose</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">{{details.avgFastingGlucose>0?details.avgFastingGlucose+' mg/dl':'---'}}</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>

    <div class="col">
        <div class="gridSub">
            <div class="col-md-12">
                <img style="width: 25%;" src="/assets/img/memberdashboard/glus1.png"/>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridTitle">Average Post Meal Glucose</div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="gridVal">{{details.avgPostMealGlucose>0?details.avgPostMealGlucose+' mg/dl':'---'}}</div> &nbsp; <div style="width: 100%; height: 100%; background: #20AA4A; border-radius: 9999px"></div>
            </div>
        </div>
    </div>
</div>