import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  private apiBaseUrl:string;

  constructor(private http: HttpClient,private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
   }

    getQuestion(id:any):Observable<any>{
      const headers = this.sharedService.createAuthorizationHeader();
      let url = this.apiBaseUrl+'questionnaires?memberId='+id;
      return this.http.get(url,{ headers });
    }
}
