<div class="row align-items-start" style="display: flex;margin-right: 30px;">
    <mat-card class="col-md-3 timeline-card ml-4" *ngFor="let timeLine of timeLines">
    <mat-card-content>
     <div class="row"><div class="col-md-1">
      <img src="/assets/img/sleepn.png" width="30" height="30" *ngIf="timeLine.type=='Sleep'"/>
      <img src="/assets/img/activityn.png" width="30" height="30" *ngIf="timeLine.type=='Activity'"/>
      <img src="/assets/img/nutritionn.png" width="30" height="30" *ngIf="timeLine.type!='Sleep' && timeLine.type!='Activity'"/>
   </div>
    <div class="col-md-8 timeline-body"><p>{{timeLine.data.time}}&nbsp;<img src="/assets/img/circle1.png" width="5" height="5" />&nbsp;{{timeLine.type}}</p></div> 
  <div class="col-md-3 timeline-body1">{{timeLine.data.toDisplay}}</div></div>
  </mat-card-content>
</mat-card>
 </div>
