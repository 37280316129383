import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { ToastService } from '../../services/toast.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-result',
  standalone: true,
  imports: [NgFor,NgIf,NgxMaterialTimepickerModule,FormsModule],
  templateUrl: './result.component.html',
  styleUrl: './result.component.css'
})
export class ResultComponent {
  @Input() mealResult:any;
  @Input() index:any;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() viewItem = new EventEmitter<string>();
  @Output() removeRow = new EventEmitter<string>();
  mealDetails:any=[];

  constructor(private router:Router,private toast:ToastService) {
   
  }

  addTime(data: any) {
    //console.log(value)
    if(data.target.value != ''){
    this.mealResult.time = data.target.value;
    //this.mealResult.type = '1';
   
    //this.newItemEvent.emit(this.mealResult);
    //let arr:any={index:this.index,val:data.target.value};
    this.newItemEvent.emit(data.target.value);
    }
  }

  removeEntry(index:any){
    this.removeRow.emit(index);
  }
  viewMealPlan(data:any){
    this.viewItem.emit(data);
    // localStorage.setItem('viewRecipe', JSON.stringify(data));
    //   console.log(data);
    //   window.open('meal-details', "_blank");
  }
  getType(unit:any,size:any,qty:any){
    let title:any;
   // console.log(unit);
    if(unit === 'grams'){
      title = size+' '+unit;
    }
    else if(unit === 'gram'){
      title = size+' '+unit;
    }else{
      title = qty+' '+unit;
    }
    return title;
}
  
}
