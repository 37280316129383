import { Injectable,inject } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: string[] = [];
  private snackBar = inject(MatSnackBar);

 errorMessage(message:any){
  this.snackBar.open(message, 'Ok',{
    duration: 3000,
     panelClass: ["style-error"]
   });
 }

 successMessage(message:any){
  this.snackBar.open(message, 'Ok',{
    duration: 3000,
     panelClass: ["style-success"]
   });
 }

}
