import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-lab-reports',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './lab-reports.component.html',
  styleUrl: './lab-reports.component.css'
})
export class LabReportsComponent {
  @Input() reports:any;
  modalDocumentURL: SafeResourceUrl;
  isImage:boolean;

  constructor(private sanitizer: DomSanitizer) {
   
  }

 viewReport(doc: any) {
    this.modalDocumentURL = this.sanitizer.bypassSecurityTrustResourceUrl('');
    if (doc.toLowerCase().endsWith('.jpeg') || doc.toLowerCase().endsWith('.jpg')) {
      this.isImage = true;
    } else if (doc.toLowerCase().endsWith('.pdf')) {
      this.isImage = false;
    } 
    this.modalDocumentURL = this.sanitizer.bypassSecurityTrustResourceUrl(doc);    
  }
 
downloadReport(doc: any,name:any) {
if(doc.includes('.pdf')){
  const a = document.createElement('a');
  a.href = doc;
  a.target = '_blank';
  a.download = name; 
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a); 
}else{
  const printWindow = window.open('', '_blank');
  if (printWindow) {
  const img = new Image();
      img.src = doc;
      img.onload = () => {
        printWindow.document.write(`
          <html>
            <head>
              <title>Report</title>
              <style>
                body {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                  margin: 0;
                  background: white;
                }
                img {
                  max-width: 100%;
                  height: auto;
                }
              </style>
            </head>
            <body>
              <img src="${doc}">
            </body>
          </html>
        `);
        // printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };

      img.onerror = () => {
        console.log(doc);
        console.error('Image failed to load.');
      };
    }else{
      console.log('Please allow popups for this website');
    }
  }
}
}
