import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fasting',
  standalone: true,
  imports: [],
  templateUrl: './fasting.component.html',
  styleUrl: './fasting.component.css'
})
export class FastingComponent {
  @Input() fastingResult:any;
  @Input() index:any;
  @Output() removeFastingRow = new EventEmitter<string>();

 removeFastingEntry(index:any){
    this.removeFastingRow.emit(index);
  }
}
