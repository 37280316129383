import { Component } from '@angular/core';

import { ResultComponent } from '../result/result.component';
import {MatListModule} from '@angular/material/list';
import { MealPlanService } from '../meal-plan.service';
import { ActivatedRoute } from '@angular/router';
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { FastingComponent } from "../fasting/fasting.component";
import { MealDetailsComponent } from '../meal-details/meal-details.component';
import { NgClass } from '@angular/common';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { MealMemberBasicDetailsComponent } from '../meal-member-basic-details/meal-member-basic-details.component';

@Component({
  selector: 'app-all-meals',
  standalone: true,
  imports: [MealMemberBasicDetailsComponent, ResultComponent, MatListModule, NgFor, NgIf, FastingComponent,MealDetailsComponent,NgClass],
  templateUrl: './all-meals.component.html',
  styleUrl: './all-meals.component.css'
})
export class AllMealsComponent {
  memberId:any;
  meals:any=[];
  mealResult:any=[];
  ongoingMeal:any=[];
  completed:any=[];
  viewDetials:any=[];
  isback:any=1;
  // isHistory:boolean = true;
  // isDetail:boolean = false;
  isCooking:boolean=false;
  isIngredient:boolean=true;
  isType:any=1;
  mealDate:any=[];
  memberDetails:any=[];
  actualWeight:any = 0; 

  constructor(private mealService:MealPlanService,private route:ActivatedRoute,private router:Router,private datePipe: DatePipe) {
      
  }

  ngOnInit(): void {
    this.memberId = this.route.snapshot.queryParamMap.get('memberId');
    this.getMeals();
    // this.getMemberDetails( this.memberId);
    // this.mealService.getVital(this.memberId).subscribe(response => { 
    //  this.actualWeight =response;
    // //console.log( this.actualWeight);
    //  if(this.actualWeight.result.bmiData !== null){
    //   this.actualWeight = this.actualWeight.result.bmiData.weight;
    //  }else{
    //   this.actualWeight = 0;
    //  }
    // });
  }
//   getMemberDetails(id:any){
//     this.mealService.getMemberDetails(id).subscribe(response => { 
//       this.memberDetails = response;
//       this.memberDetails = this.memberDetails.result;
//      // console.log(this.memberDetails);
//   });
// }
  getMeals(){
    this.mealService.getMeals(this.memberId).subscribe(response => { 
      this.meals = response;
     //console.log(this.meals);
      let details:any=[];
      let days=['monday','tuesday','wednesday','thursday','friday','saturday','sunday'];
      let mealPlan:any=[];
      let dates:any=[];
     for(let i=0;i<this.meals.length;i++){
      this.ongoingMeal.push([]);
      this.completed.push([]);
         
      this.mealDate.push({startDt:this.meals[i].fromDate,endDt:this.meals[i].toDate,status:this.meals[i].status});
          mealPlan = this.meals[i].mealPlan;
          for(let j=0;j<7;j++){
            if(mealPlan[days[j]].length <1){
              details ={day:days[j],meals:[]};
            }else{
               // console.log(mealPlan[days[j]][0]);
              details={day:days[j],meals:mealPlan[days[j]][0].meals};
            }
            if(this.meals[i].status == 'ongoing'){
              this.ongoingMeal[i].push(details); 
            }
            else if(this.meals[i].status == 'completed'){
              this.completed[i].push(details); 
            }
          }
          
     }
     this.mealResult =this.ongoingMeal;
    // console.log(this.mealResult);
  });
  }

  viewMealPlan(id:any){
    
      this.mealService.getMealDetail(id).subscribe(response => { 
        this.viewDetials = response;
        this.viewDetials = this.viewDetials.recipeUI;
       // console.log( this.viewDetials );
      });
      this.isback = 2;
     
      
  }

  filterPlans(type:any){
    if(type == 1){
      this.mealResult =this.ongoingMeal;
      this.isType = 1;
    }else if(type == 2){      
      this.mealResult =this.completed;
      this.isType = 2;
      //console.log(this.mealResult)
    }
  }

  tabShow(type:any){
    if(type == 1){
      this.isIngredient = true;
      this.isCooking =false;
      this.isType = 1;
    }else{
      this.isIngredient = false;
      this.isCooking =true;
      this.isType = 2;
    }
      
  }

  backBtn(){
    if(this.isback ==2){
      this.isback = 1;
    
    }else{
      this.router.navigate(['/meal-plans']);
    }
  }
  getType(unit:any,size:any,qty:any){
    let title:any;
   // console.log(unit);
    if(unit === 'grams'){
      title = size+' '+unit;
    }
    else if(unit === 'gram'){
      title = size+' '+unit;
    }else{
      title = qty+' '+unit;
    }
    return title;
}

getDate(selectedTime:any){
  const timeParts = selectedTime.split(':');
  const selectedDate = new Date();
  selectedDate.setHours(parseInt(timeParts[0], 10));
  selectedDate.setMinutes(parseInt(timeParts[1], 10));
 return this.datePipe.transform(selectedDate, 'hh:mm a');
}
getDateFormat(selectedTime:any){
  // const timeParts = selectedTime.split(':');
 // console.log('here',selectedTime);
  const selectedDate = this.convertDateToUTC(new Date(selectedTime));
 
 return this.datePipe.transform(selectedDate, 'd MMM Y');
}

 convertDateToUTC(date:any) { 
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
 }
}
