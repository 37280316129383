import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ControllerApiPath } from '../services/constant';
import { SharedServiceService } from '../services/shared-service.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private apiBaseUrl:string;

  constructor(private http: HttpClient,private sharedService: SharedServiceService) {
    this.apiBaseUrl = sharedService.baseUrl();
   }

  getLoggedUser(){
    return this.sharedService.getLoggedInUser();
  }

  getCriticalRisk(){
    const user = this.getLoggedUser();
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.members+'/criticalmembers?loggedUserId='+user.id;    
    return this.http.get(url,{ headers });
  }

  getEvents(date:any){
    let user  = this.getLoggedUser();
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.events+'?loggedInUserId='+user.id+'&filterDate='+date;    
    return this.http.get(url,{ headers });
  }

  getCounts(){
   
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.members+'/count';    
    return this.http.get(url,{ headers });
  }

  markResolve(id:any){
    const headers = this.sharedService.createAuthorizationHeader();
    const url = this.apiBaseUrl + ControllerApiPath.members+'/resolvecriticalalert?memberId='+id;
    return this.http.get(url,{ headers });
   }

   advocateMessage(data:any){
    const headers = this.sharedService.createAuthorizationHeader();
    return  this.http.post(this.apiBaseUrl + ControllerApiPath.user+'/savememberadvocatemessage', data, { headers })
   }
}
