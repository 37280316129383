<div class="row align-items-start" style="display: flex;margin-right: 30px;">
    <mat-card class="col-md-3 insight-card ml-4" *ngFor="let insight of insights">
    <mat-card-header>
     <div class="row"> <p class="insight-body">{{insight.date}}</p></div>
    
    </mat-card-header>
     
    <mat-card-content>
     <div class="row"><div class="col-md-1"><img src="/assets/img/sleepn.png" width="30" height="30" *ngIf="insight.type=='sleep'"/>
    
    <img src="/assets/img/activityn.png" width="30" height="30" *ngIf="insight.type=='activity'"/>
    <img src="/assets/img/nutritionn.png" width="30" height="30" *ngIf="insight.type=='food'"/>
   </div>
     
    <div class="col-md-11 insight-body"><p>{{insight.content}}</p></div> </div>
</mat-card-content>

  </mat-card>
 
  </div>
