<div class="col-md-12 flex mt-3">
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/eat1.png"/>
            </div>
        <div class="col-md-12 mt-3 gridTitle">
                <div><span>Breakfast</span><span style="margin-left: 125px;">{{eat && eat.breakfast && eat.breakfast.percentage?eat.breakfast.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{eat && eat.breakfast && eat.breakfast.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Calories taken: {{eat && eat.breakfast && eat.breakfast.average?eat.breakfast.average +" cal":'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{eat && eat.breakfast && eat.breakfast.goal?eat.breakfast.goal+" cal":'---'}}</div>
            </div>

        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/eat2.png"/>
            </div>
           <div class="col-md-12 mt-3 gridTitle">
                <div><span>Lunch</span><span style="margin-left: 125px;">{{eat && eat.lunch && eat.lunch.percentage?eat.lunch.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{eat && eat.lunch && eat.lunch.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Calories taken: {{eat && eat.lunch && eat.lunch.average?eat.lunch.average +" cal":'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{eat && eat.lunch && eat.lunch.goal?eat.lunch.goal+" cal":'---'}}</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/eat3.png"/>
            </div>
              <div class="col-md-12 mt-3 gridTitle">
                <div><span>Dinner</span><span style="margin-left: 125px;">{{eat && eat.dinner && eat.dinner.percentage?eat.dinner.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{eat && eat.dinner && eat.dinner.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Calories taken: {{eat && eat.dinner && eat.dinner.average?eat.dinner.average +" cal":'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{eat && eat.dinner && eat.dinner.goal?eat.dinner.goal+" cal":'---'}}</div>
            </div>
        </div>
    </div>
    <div class="col ">
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/memberdashboard/eat3.png"/>
            </div>
              <div class="col-md-12 mt-3 gridTitle">
                <div><span>Snacks</span><span style="margin-left: 125px;">{{eat && eat.snacks && eat.snacks.percentage?eat.snacks.percentage +"%":'---'}}</span></div>
                <p>
                    <mat-progress-bar mode="determinate" value="{{eat && eat.snacks && eat.snacks.percentage}}" class="mt-3"></mat-progress-bar>
                </p> 
            </div>
            <div class="col-md-12 mt-2">
                <div class="desc">Overall Calories taken: {{eat && eat.snacks && eat.snacks.average?eat.snacks.average +" cal":'---'}}</div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="goal">Goal: {{eat && eat.snacks && eat.snacks.goal?eat.snacks.goal+" cal":'---'}}</div>
            </div>
        </div>
    </div>
    </div>