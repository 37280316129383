import { Component } from '@angular/core';
import {
 
  OnInit,
  ViewEncapsulation,
  Inject,
  AfterViewInit,
} from '@angular/core';
import {
  Event,
  Router,
  ActivatedRoute,
  NavigationStart
  
} from '@angular/router';
import { RouterOutlet } from '@angular/router';
import { TopNavComponent } from './top-nav/top-nav.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NgIf } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BreakpointObserver,Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,TopNavComponent,NavbarComponent,NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
 
})




export class AppComponent {
  
  title = 'dhruva';
  adminShow: boolean = true;
  notLoggedIn:boolean=true;
  userDetails:any =[];
  isPhoneviewed = false;
  isMenu:boolean=true;
  constructor(public responsive: BreakpointObserver,private router:Router,private route: ActivatedRoute, public Router: Router) {
    this.userDetails = localStorage.getItem('user');
    Router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        
        let checkUrl = event.url.includes("accept-invite");
        console.log(event.url);
        if (
          event.url === '/' ||  event.url === '' || event.url === '/forgot-password'||
          checkUrl === true || event.url === '/reset-password' || event.url === "/reset-password-link"
        ) {
          this.adminShow = false;
        } else {
          this.notLoggedIn =false;
          this.adminShow = true;
          if(localStorage.getItem('dhruvaUser') == ''){
           // this.toastr.error('Your session expired.');
            this.notLoggedIn = false;
          //  window.location.href="/";
            //location.reload();
            
          }
        }
      }
      
    });
  }

  ngOnInit() {
    this.responsive.observe([
      Breakpoints.HandsetLandscape,      
      Breakpoints.TabletLandscape,
      ])
      .subscribe(result => {
        console.log(result);
    
        if (result.matches) {
          this.isMenu = true;
        }else{
          this.isMenu = true;
        }

  });
// this.responsive.observe(Breakpoints.HandsetPortrait)
// .subscribe(result => {

// this.isPhoneviewed = false;

// if (result.matches) {
// this.isPhoneviewed = true;
// } {
// console.log(
// 'HandsetPortrait is on'
// );
// }

// });
}
}
