import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-meal-notification',
  standalone: true,
  imports: [NgIf],
  templateUrl: './meal-notification.component.html',
  styleUrl: './meal-notification.component.css'
})
export class MealNotificationComponent {
@Input() expiringCount:number;
@Input() unassignedCount:number;
closeNotification(type:string){
  if(type == 'expiring'){
    this.expiringCount = 0;
  }else{
    this.unassignedCount = 0;
  }
}

showNotfns(type:string){
  if(type == 'expiring'){
    return this.expiringCount > 0;
  }else{
   return this.unassignedCount > 0;
  }
}
}
