import { Component, EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import{CommonModule} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { 
  formatDate 
 } 
  from '@angular/common';
  

@Component({
  selector: 'app-member-info',
  standalone: true,
  imports: [MatDatepickerModule,MatInputModule,MatNativeDateModule,MatDateRangeInput,
            MatHint,MatLabel,MatFormFieldModule,CommonModule,FormsModule],
  templateUrl: './member-info.component.html',
  styleUrl: './member-info.component.css'
})
export class MemberInfoComponent {
  @Input() memberbasic:any;
  @Input() screentype:any;
  @Input() vital:any;
  @Input() conditions:any;
  @Output() onDatePicked = new EventEmitter<any>();
    currentDate:any = new Date();
    public extractByDate(date:HTMLInputElement){
     console.log(date.value);
     console.log(formatDate(date.value, 'yyyy-MM-dd','en-US'));
     this.onDatePicked.emit(formatDate(date.value, 'yyyy-MM-dd','en-US'));
  }
}
