import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { InjectionToken } from '@angular/core';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpClient, provideHttpClient,HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoogleLoginProvider,  SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {MatDialogModule,MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ErrorInterceptor } from './interceptor/error-interceptor.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('36000');
export const appConfig: ApplicationConfig = {
  providers: [{provide:ToastrModule, useValue: ToastrService},{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },{ provide: DEFAULT_TIMEOUT, useValue: 36000 },{provide: DatePipe},{provide: NgModule},{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideAnimationsAsync(),provideHttpClient(),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              //'584313391618-1b0utgfad1b74n1g6iela58504eqvppm.apps.googleusercontent.com'
              '693033811375-rrbm6pabsb6afae5iq72hqvjunlnka76.apps.googleusercontent.com'
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  
};
