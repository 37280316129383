<div class="row mt-4">
    <app-member-info [memberbasic]="memberList" [vital]="vitalDetails" [conditions]="glucoseDetails.conditions" (onDatePicked)="extractData($event)"></app-member-info>
   
</div>

<app-score [scores]="scores"></app-score>

<div class="row mt-4">
    <div class="labReport">Recent Lab Reports</div>
</div>

<app-lab-reports [reports]="labList"></app-lab-reports>

<div class="row mt-4">
    <div class="labReport">Glucose Mapping</div>
    </div>
    <div class="row">
   <div class="mt-3" >
        <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [(ngModel)]="previousDate" #dateRangeStart >
                <input matEndDate placeholder="End date" [(ngModel)]="currentDate" #dateRangeEnd (dateChange)="filterByDate(dateRangeStart, dateRangeEnd)">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>
</div>

<div class="row mt-3">
    <app-glucose [glucoseDetails]="glucoseDetails" style="width:100%"></app-glucose>
</div>
<div class="row mt-4">
    <div class="labReport">Insights</div>
    </div>
<div class="row flex">
            <div class="col-12 text-right insight-arrow" *ngIf="this.glucoseDetails && this.glucoseDetails.insights && this.glucoseDetails.insights.length>0">
                <a class="btn arrow mb-3"
                   href="#carouselExampleIndicators1"
                   role="button"
                   data-slide="next">
                    <i class="fa fa-arrow-right"></i>
                </a> &nbsp;
                <a class="btn arrow mb-3 mr-1" 
                   href="#carouselExampleIndicators1"
                   role="button"
                    data-slide="prev">
                    <i class="fa fa-arrow-left"></i>
                </a>
                
              </div>
              <div  id="carouselExampleIndicators1" class="carousel slide"
              data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item"  *ngFor='let insight of insights;let i=index;' [ngClass]="{'active': i <1}">                 
                    <app-insights [insights]="insight" class="fullWidth"></app-insights>
                </div>
            </div>
        </div>
 </div>

<div class="row mt-4">
    <div class="labReport">Daily TimeLine</div>
    </div>
<div class="row flex">
            <div class="col-12 text-right insight-arrow" *ngIf="this.glucoseDetails && this.glucoseDetails.timeLine && this.glucoseDetails.timeLine.length>0">
                <a class="btn arrow mb-3"
                   href="#carouselExam"
                   role="button"
                   data-slide="next">
                    <i class="fa fa-arrow-right"></i>
                </a> &nbsp;
                <a class="btn arrow mb-3 mr-1" 
                   href="#carouselExam"
                   role="button"
                    data-slide="prev">
                    <i class="fa fa-arrow-left"></i>
                </a>
                
              </div>
              <div id="carouselExam" class="carousel slide"
              data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item"  *ngFor='let timeLine of timeLines;let i=index;' [ngClass]="{'active': i <1}">                 
                    <app-timelines [timeLines]="timeLine" class="fullWidth"></app-timelines>
                </div>
            </div>
        </div>
 </div>
<div class="row mt-4">
    <div class="labReport">Glucose Summary</div>
    </div>
    <div class="row mt-3">
<mat-form-field>
                    <mat-label>Pick date</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="currentDateGS" #dateRangeEnd2 (dateChange)="extractSummary(dateRangeEnd2)">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
     </mat-form-field>
</div>
<div class="row mt-3">
<app-glucose-summary [glucoseSummary]="glucoseSummary"></app-glucose-summary>
</div>


<div class="row mt-4">
    <div class="col-md-12 labReport">Lifestyle Habits</div>
</div>
 
<div class="row mt-4">
    <div class="col-md-12 labReport">Nutrition</div>
</div>
<div class="row">
   <div class="mt-3" >
        <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker3">
                <input matStartDate placeholder="Start date" [(ngModel)]="previousDateNU" #dateRangeStart3 >
                <input matEndDate placeholder="End date" [(ngModel)]="currentDateNU"  #dateRangeEnd3 (dateChange)="extractNutData(dateRangeStart3, dateRangeEnd3)">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-date-range-picker #picker3></mat-date-range-picker>
        </mat-form-field>
    </div>
</div>

<div class="row mt-3">
    <app-life-style [lifeStyle]="lifeStyle" class="fullWidth"></app-life-style>
</div>

<div class="row mt-4">
    <div class="col-md-12 labReport">Sleep</div>
</div>
<div class="row mt-3">
        <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker4">
                <input matStartDate placeholder="Start date" [(ngModel)]="previousDateSL" #dateRangeStart4 >
                <input matEndDate placeholder="End date" [(ngModel)]="currentDateSL" #dateRangeEnd4 (dateChange)="extractSleepData(dateRangeStart4, dateRangeEnd4)">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
              <mat-date-range-picker #picker4></mat-date-range-picker>
        </mat-form-field>
</div>
<div class="row mt-3">
    <app-sleep [sleep]="sleep" class="fullWidth"></app-sleep>
</div>

<div class="row mt-4">
    <div class="col-md-12 labReport">Activity</div>


    <div class="col-md-12 glucoseSub mt-3">Movement Data</div>
</div>
<div class="row mt-3">

        <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker5">
                <input matStartDate placeholder="Start date" [(ngModel)]="previousDateAC" #dateRangeStart5 >
                <input matEndDate placeholder="End date" [(ngModel)]="currentDateAC"  #dateRangeEnd5 (dateChange)="extractActivityData(dateRangeStart5, dateRangeEnd5)">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker5"></mat-datepicker-toggle>
              <mat-date-range-picker #picker5></mat-date-range-picker>
        </mat-form-field>
</div>
<div class="row mt-3">
    <app-activity [activity]="activity" class="fullWidth"></app-activity>
</div>



