import { Component, Input } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-glucose-summary',
  standalone: true,
  imports: [HighchartsChartModule],
  templateUrl: './glucose-summary.component.html',
  styleUrl: './glucose-summary.component.css'
})
export class GlucoseSummaryComponent {
  @Input() glucoseSummary:any;

  Highcharts: typeof Highcharts = Highcharts;
  coptions: Highcharts.Options = {};
  
  ngOnChanges(): void { 
    let veryHigh = 0;
    let high = 0;
    let inRange = 0;
    let low = 0;
    let veryLow = 0;
    if(this.glucoseSummary && this.glucoseSummary.range){
      veryHigh = this.glucoseSummary.range.veryHigh;
      high = this.glucoseSummary.range.high;
      inRange = this.glucoseSummary.range.inRange;
      low = this.glucoseSummary.range.low;
      veryLow = this.glucoseSummary.range.veryLow;
    }
   this.coptions =  {
    chart: {
        backgroundColor: '#F9F9F9',
        type: 'bar'
    },
    title: {
        text: ''
    },
    xAxis: {
      visible: false,
        categories: ['']
    },
    yAxis: {
      visible: false,
        title: {
            text: ''
        }
    },
    
   credits: {
    enabled: false
  },
      tooltip: {
      formatter: function() {
        if(this.y === 0){
          return false;
        }
        return this.series.name +' : '+ this.y + ' %';
      },
      backgroundColor: 'rgba(0,0,0, 0.6)',
      borderWidth: 1,
      shadow: true,
      style: {
        color: '#fff',
        fontSize: '10pt',
        fontFamily: 'Poppins, sans-serif'
      }
    },
    legend: {
        reversed: true
    },
    plotOptions: {
      
        series: {
            stacking: 'normal',
            dataLabels: {
                enabled: false
            }
        }
    },
    series: [{
        type: 'bar',
        name: 'Very High',
        data: [veryHigh],
        color: '#FF7733'
    }, {
        type: 'bar',
        name: 'High',
        data: [high],
        color: '#F2B90F'
    }, {
        type: 'bar',
        name: 'In Range',
        data: [inRange],
        color: '#65A603'
    }, {
        type: 'bar',
        name: 'Low',
        data: [low],
        color: '#FF4B26'
    }, {
        type: 'bar',
        name: 'Very Low',
        data: [veryLow],
        color: '#E62E2E'
    }]
};
  }

}
