<div class="row mt-5" >
    <div class="col-3 flex">
        <div class="col-lg-3"><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
    <div class="col-lg-6 name mt-1" >
      <div class="col-md-12 name">
         {{memberDetails.firstName}}
      </div>
      <div class="col-md-12 age" >
        Age : {{memberDetails.age}}
    </div>
    </div>
    </div>
    
    <div class="col-2 borderClass">
       <div class="col-md-12 name">
        Actual Weight
       </div>
       <div class="col-md-12 age" *ngIf="actualWeight != '[object Object]]'">
        {{actualWeight}} kg
    </div>
    </div>
    
    <!-- <div class="col-2">
        <div class="col-md-12 name">
            Target Weight
           </div>
           <div class="col-md-12 age" >
            50 kg
        </div>
    </div> -->
    
  
  </div>
 