import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgForOf } from '@angular/common';
import { NgIf } from '@angular/common';
import { 
  formatDate 
 } 
  from '@angular/common';

@Component({
  selector: 'app-insights',
  standalone: true,
  imports: [MatCardModule,NgForOf,NgIf],
  templateUrl: './insights.component.html',
  styleUrl: './insights.component.css'
})
export class InsightComponent {
@Input() insights:any;
ngOnInit(): void {
  if(this.insights){
for(let i=0;i<this.insights.length;i++){
  this.insights[i].date = formatDate(this.insights[i].date, 'yyyy-MM-dd','en-US');
}
  }
}
}
