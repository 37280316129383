<!-- <body style="font-family: DM Sans;    overflow-x: hidden;" [hidden]="notLoggedIn"> -->
    <body style='font-family: "DM Sans-Regular", Helvetica !important;    overflow-x: hidden;' *ngIf="notLoggedIn ==false">
        
<div class="main"  style="background:#F9F9F9">
<app-top-nav ></app-top-nav>
<div class="row">
<div class="col-md-1" *ngIf="isMenu">
<app-navbar></app-navbar>
</div>
<div class="col-md-11 ofset-2" style="margin-top:55px;">
    
<router-outlet ></router-outlet>
</div>
</div>
</div>
</body>
<div  *ngIf="notLoggedIn == true" style="overflow-x: hidden;">

<router-outlet ></router-outlet>
</div>