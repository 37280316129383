import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-preference',
  standalone: true,
  imports: [NgFor,NgIf,FormsModule,NgClass],
  templateUrl: './preference.component.html',
  styleUrl: './preference.component.css'
})
export class PreferenceComponent {
  @Input() allPrefer:any;
  @Input() selectedType:any;
  isSingle:boolean=false;
  isAddValue:any=0;
  preferValue:any='';
  isKidFriendly:any=1;
  isPlanFriendly:any=1;
  
  constructor(private toast:ToastService) {
      
  }
   ucfirst(str:any) {
    var firstLetter = str.slice(0,1);
    return firstLetter.toUpperCase() + str.substring(1);
}
  savePreference(){
    console.log(this.allPrefer);
    let checkDuplicate = 1;
    var regex = new RegExp("^[a-zA-Z ]+$");
    //var str = String.fromCharCode(this.preferValue);
    if (regex.test(this.preferValue)) {
      
 

    if(this.preferValue == '' ||  this.preferValue == null){
      this.toast.errorMessage('Please enter the value.');
    }else{
      this.preferValue = this.preferValue.trim();
      let data = this.preferValue.split(' ');
      let word:any;
      for(let i=0;i<data.length;i++){
        if(i< 1){
          word = this.ucfirst(data[i]);
          console.log(word);
        }else{
          word = word +' '+this.ucfirst(data[i]);
        }
       
      }
     // this.preferValue = this.preferValue.toUpperCase();
      this.preferValue = word;
    console.log(this.allPrefer.cuisine.likedCuisines);
   
     if(this.isAddValue == 1){

      if(this.allPrefer.cuisine.defaultCuisines.indexOf(this.preferValue) !== -1) {
        //console.timeLog('Name already exists!');
        checkDuplicate = 2;
      }else{
        //const words = this.preferValue;

        this.allPrefer.cuisine.defaultCuisines.push(this.preferValue);
      }
     
    }
    else if(this.isAddValue == 2){
      if(this.allPrefer.cuisine.likedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.cuisine.dislikedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.cuisine.likedCuisines.push(this.preferValue);
      }
      
    }
    else if(this.isAddValue == 3){
      if(this.allPrefer.cuisine.dislikedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.cuisine.likedCuisines.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       
        //this.allPrefer.cuisine.dislikedCuisines.push(words[0].toUpperCase() + words.substring(1));
        this.allPrefer.cuisine.dislikedCuisines.push(this.preferValue);
      }
     // this.allPrefer.cuisine.dislikedCuisines.push(this.preferValue);
    }
    else if(this.isAddValue == 4){
      if(this.allPrefer.cuisine.foodRestrictions.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }else{
       
        this.allPrefer.cuisine.foodRestrictions.push(this.preferValue);
      }
     // this.allPrefer.cuisine.foodRestrictions.push(this.preferValue);
    }
    else if(this.isAddValue == 5){
      if(this.allPrefer.likedIngredients.breakfast.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.dislikedIngredients.breakfast.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.likedIngredients.breakfast.push(this.preferValue);
      }
      //this.allPrefer.likedIngredients.push(this.preferValue);
    }
    else if(this.isAddValue == 6){
      if(this.allPrefer.dislikedIngredients.breakfast.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.likedIngredients.breakfast.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.dislikedIngredients.breakfast.push(this.preferValue);
      }
      //this.allPrefer.dislikedIngredients.push(this.preferValue);
    }
    else if(this.isAddValue == 7){
      if(this.allPrefer.likedIngredients.lunch.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.dislikedIngredients.lunch.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.likedIngredients.lunch.push(this.preferValue);
      }
      //this.allPrefer.likedIngredients.push(this.preferValue);
    }
    else if(this.isAddValue == 8){
      if(this.allPrefer.dislikedIngredients.lunch.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.likedIngredients.lunch.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.dislikedIngredients.lunch.push(this.preferValue);
      }
      //this.allPrefer.dislikedIngredients.push(this.preferValue);
    }
    else if(this.isAddValue == 9){
      if(this.allPrefer.likedIngredients.dinner.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.dislikedIngredients.dinner.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.likedIngredients.dinner.push(this.preferValue);
      }
      //this.allPrefer.likedIngredients.push(this.preferValue);
    }
    else if(this.isAddValue == 10){
      if(this.allPrefer.dislikedIngredients.dinner.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.likedIngredients.dinner.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.dislikedIngredients.dinner.push(this.preferValue);
      }
      //this.allPrefer.dislikedIngredients.push(this.preferValue);
    }

    else if(this.isAddValue == 11){
      if(this.allPrefer.likedIngredients.snack.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.dislikedIngredients.snack.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.likedIngredients.snack.push(this.preferValue);
      }
      //this.allPrefer.likedIngredients.push(this.preferValue);
    }
    else if(this.isAddValue == 12){
      if(this.allPrefer.dislikedIngredients.snack.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else if(this.allPrefer.likedIngredients.snack.indexOf(this.preferValue) !== -1) {
        checkDuplicate = 2;
      }
      else{
       // const words = this.preferValue;
        this.allPrefer.dislikedIngredients.snack.push(this.preferValue);
      }
      //this.allPrefer.dislikedIngredients.push(this.preferValue);
    }

    if(checkDuplicate >  1){
      this.toast.errorMessage('Name is already exit.');
      checkDuplicate = 1;
    }else{
      this.toast.successMessage('Preference is added successfully.');
    }
    this.preferValue = '';
    this.isAddValue = 0;
  }
}else{
this.toast.errorMessage('Enter only characters.')
//  this.preferValue.preventDefault();
  return false;
  }
  }

  removePreference(index:any,type:any){
    if(type == 1){     
      this.allPrefer.cuisine.defaultCuisines.splice(index,1);
    }
    else if(type == 2){     
      this.allPrefer.cuisine.likedCuisines.splice(index,1);
    }
    else if(type == 3){     
      this.allPrefer.cuisine.dislikedCuisines.splice(index,1);
    }
    else if(type == 4){     
      this.allPrefer.cuisine.foodRestrictions.splice(index,1);
    }
    else if(type == 5){     
      this.allPrefer.likedIngredients.breakfast.splice(index,1);
    }
    else if(type == 6){     
      this.allPrefer.dislikedIngredients.breakfast.splice(index,1);
    }

    else if(type == 7){     
      this.allPrefer.likedIngredients.lunch.splice(index,1);
    }
    else if(type == 8){     
      this.allPrefer.dislikedIngredients.lunch.splice(index,1);
    }

    else if(type == 9){     
      this.allPrefer.likedIngredients.dinner.splice(index,1);
    }
    else if(type == 10){     
      this.allPrefer.dislikedIngredients.dinner.splice(index,1);
    }

    else if(type == 11){     
      this.allPrefer.likedIngredients.snack.splice(index,1);
    }
    else if(type == 12){     
      this.allPrefer.dislikedIngredients.snack.splice(index,1);
    }
    
    this.toast.successMessage('Preference is removed successfully.');
  }

  kidFriendly(type:any){
    
      if(type == 1){
        this.allPrefer.lifeStyle.isKidFriendly = false;
        this.isKidFriendly =2;
      }else{
        this.allPrefer.lifeStyle.isKidFriendly = true;
        this.isKidFriendly =1;
      }
  }

  planFriendly(type:any){
    
    if(type == 1){
      this.allPrefer.lifeStyle.isMealPlanningFriendly = false;
      this.isPlanFriendly =2;
    }else{
      this.allPrefer.lifeStyle.isMealPlanningFriendly = true;
      this.isPlanFriendly =1;
    }
}



addPreference(type:any){
  this.isAddValue = type;
}


}
