<div class="memberPartern">
<app-basic-details [screentype] = "1" [memberMealDetails] = "memberMealDetails" [selectedTab]="selectedTab"></app-basic-details>
<div class="row mt-4" *ngIf="selectedTab!='tab2'">
    <div class="col-sm">
         
        <div class="col-md-12 labels">
            Remaining Meals
        </div>
        <div class="col-md-12 mt-2 labelContent">
          {{memberMealDetails.mealPlanId!=''?memberMealDetails.remainingMeals+' Meals':'---'}} 
        </div>

    </div>

    <div class="col-sm">
        <div class="col-md-12 labels">
            Due Date

        </div>
        <div class="col-md-12 mt-2 labelContent">
             {{memberMealDetails.mealPlanId!=''?this.date:'---'}} 
        </div>

    </div>
</div>
<div class="row mt-4"  *ngIf="selectedTab!='tab2'">
    <div class="col-sm">
        <a class="btn btnFirst btnLabel" [routerLink]="['/create-plan']" [queryParams]="{ memberId:memberMealDetails.memberId}" href="#" >Create Meal Plan</a>

    </div>

    <div class="col-sm">
        <button class="btn btnSec btnLabel ml-2"  [routerLink]="['/all-meal-plans']" [queryParams]="{ memberId:memberMealDetails.memberId}" href="#" [disabled]="memberMealDetails.mealPlanId==''">View Meal Plan</button>
    </div>
</div>
</div>