<div class="col-md-12 flex mt-2">
    <div class="col-md-6 maxWidth" >
        <div class="gridSub">
            <div class="col-md-12">
                <img width="30" height="30" src="/assets/img/arrown.png"/> 
            </div>
            <div class="row mt-3">
                <div class="col-md-5">
                    <p class="gridTitle">Glucose Variability</p>
                    <p class="gridVal">{{glucoseSummary && glucoseSummary.variability && glucoseSummary.variability.isCurReadingPresent?glucoseSummary.variability.currentReading + ' mg/dl':'---'}}</p>
                </div>
                <div class="col-md-1" style=" border-left: 1px solid black;height: 70px;"></div>
                <div class="col-md-6"><p class="gridTitle">Vs Previous 7 Days</p><p class="gridVal">{{glucoseSummary && glucoseSummary.variability && glucoseSummary.variability.is7DReadingsPresent?glucoseSummary.variability.sevenDaysReading + ' mg/dl':'---'}}</p></div>
                <p style="height: 100px;"></p>
            </div>
        </div>
    </div>

    <div class="col-md-6 minWidth"  >
        <div class="gridSub">
            <div class="col-md-12">
                <img src="/assets/img/bars.png" width="30" height="30"/>
            </div>
          <div class="row mt-3">
                <div class="col-md-2">
                    <p class="gridTitle" style="padding-left: 10px;">TIR</p>
                    <p class="gridVal" style="padding-left: 10px;">{{glucoseSummary && glucoseSummary.range?glucoseSummary.range.inRange + ' %':'---'}}</p>
                </div>
                <div class="col-md-10">
                    <highcharts-chart 
  [Highcharts]="Highcharts"
  [options]="coptions"
  style="width: 90%; height: 120px; display: block;"
></highcharts-chart>
                </div>
            </div>
        </div>
    </div>
</div>