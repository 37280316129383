import { Component } from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import { RouterLink } from '@angular/router';
import { SharedServiceService } from '../services/shared-service.service';
import { NgIf } from '@angular/common';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MatSidenavModule,RouterLink,NgIf],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {
  oldDhruva = environment.oldDhruvaUrl;
  userRole:any=[];
  constructor(private SharedService:SharedServiceService) {
  }

  ngOnInit(): void {
    this.userRole= this.SharedService.getloggedUserRole();
    
  }
}
