import { Component } from '@angular/core';
import { MemberInfoComponent } from './member-info/member-info.component';
import { ScoreComponent } from './score/score.component';
import { LabReportsComponent } from './lab-reports/lab-reports.component';
import { GlucoseComponent } from './glucose/glucose.component';
import { LifeStyleComponent } from './life-style/life-style.component';
import { SleepComponent } from './sleep/sleep.component';
import { ActivityComponent } from './activity/activity.component';
import { InsightComponent } from './insights/insights.component';
import { TimeLineComponent } from './timelines/timelines.component';
import { GlucoseSummaryComponent } from './glucose/glucose-summary/glucose-summary.component';
import { MemberDashboardService } from './member-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import {formatDate} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-member-dashboard',
  standalone: true,
  imports: [MemberInfoComponent,ScoreComponent,LabReportsComponent,GlucoseComponent,LifeStyleComponent,SleepComponent,
            ActivityComponent,MatInputModule,MatNativeDateModule,MatDateRangeInput,MatHint,MatLabel,MatFormFieldModule,
            MatDatepickerModule,InsightComponent,CommonModule,TimeLineComponent,GlucoseSummaryComponent,FormsModule],
  templateUrl: './member-dashboard.component.html',
  styleUrl: './member-dashboard.component.css'
})
export class MemberDashboardComponent {
  
memberList:any=[];
scores:any=[];
labList:any=[];
lifeStyle:any=[];
activity:any=[];
sleep:any=[];
glucoseDetails:any=[];
glucoseSummary:any;
insights:any=[];
timeLines:any=[];
currentDate:any;
previousDate:any;
currentDateGS:any;
currentDateNU:any;
previousDateNU:any;
previousDateSL:any;
currentDateSL:any;
previousDateAC:any;
currentDateAC:any;
endDate:any = formatDate(Date.now(), 'Y-MM-d', 'en');
startDate:any = formatDate(new Date().setDate(new Date().getDate()-1), 'Y-MM-d', 'en');
memberId:any=this.route.snapshot.queryParamMap.get('memberId');
vitalDetails:any;
constructor(private dashboard:MemberDashboardService,private route:ActivatedRoute) {
  var d = new Date();
  var p = new Date(new Date().setDate(new Date().getDate()-1));
  this.currentDate= d
  this.previousDate= p;
  this.currentDateGS= d
  this.currentDateNU= d
  this.previousDateNU= p
  this.previousDateSL= p
  this.currentDateSL= d
  this.previousDateAC= p
  this.currentDateAC= d
}

ngOnInit(): void {
 
  this.getMemberById();
  this.getMemberScore(formatDate(new Date(), 'Y-MM-d', 'en'));
  this.getLabWork();
  this.getVitalData(formatDate(new Date(), 'Y-MM-d', 'en'));
  this.getGlucose(this.startDate,this.endDate);
  this.getGlucoseSummary(formatDate(new Date(), 'Y-MM-d', 'en'));
  this.getNutritionData(this.startDate,this.endDate);
  this.getSleepData(this.startDate,this.endDate);
  this.getActivityData(this.startDate,this.endDate);
}

public extractData(date: any):void {
    this.getMemberScore(date);
    this.getVitalData(date);
}
getMemberById(){
  this.dashboard.getMemberById(this.memberId).subscribe(response => {    
      this.memberList = response;
      this.memberList = this.memberList.result;
  });
}

getMemberScore(data:any){
  this.dashboard.getMemberScore(this.memberId,data).subscribe(response => {    
    this.scores = response;
    this.scores = this.scores.result;
});
}

getLabWork(){
  this.dashboard.getLabWork(this.memberId).subscribe(response => {    
    this.labList = response;
    this.labList = this.labList.result;
    this.labList.forEach((lab:any) => {
      if(lab.reportName == null || lab.reportName == ''){
        if(lab.type == 'labReport'){
          let n = lab.uploadFileURL.split('/').pop();
          lab.reportName = n.substring(n.indexOf('_')+1,n.length);;
        }else{ 
          lab.reportName = lab.uploadFileURL.split('/').pop();
        }
      }
    });
  });
}

getVitalData(data:any){
  this.dashboard.getVital(this.memberId,data).subscribe(response => {    
    this.vitalDetails = response;
    this.vitalDetails = this.vitalDetails.result;
});
}

  filterByDate(StartDate:HTMLInputElement,endDate:HTMLInputElement){
     if(StartDate.value != '' && endDate.value != ''){
      this.startDate = StartDate.value;
      this.endDate = endDate.value;
      this.getGlucose(StartDate.value,endDate.value);
     } 
  }
  getGlucoseSummary(date:any){
    this.dashboard.getGlucoseSummary(this.memberId,date).subscribe(response => {    
      this.glucoseSummary = response;
      this.glucoseSummary = this.glucoseSummary.result;
  });

  }
  getGlucose(startDate:any,endDate:any){
  
    this.dashboard.getGlucose(this.memberId,startDate,endDate).subscribe(response => {    
        this.glucoseDetails = response;
        this.glucoseDetails = this.glucoseDetails.result;
     
       this.insights=[];
         let k=0;
      let m=0;
      this.insights.push([]);
      for(let j=0;j< this.glucoseDetails.insights.length;j++){
        
          if(m > 2){
            this.insights.push([]);
            m=1;
            k++;
            
          }else{
            m++;
          }
          this.insights[k].push(this.glucoseDetails.insights[j]);
      }
      

      this.timeLines=[];
      k=0;
      m=0;
      this.timeLines.push([]);
      for(let j=0;j< this.glucoseDetails.timeLine.length;j++){
        
          if(m > 2){
            this.timeLines.push([]);
            m=1;
            k++;
            
          }else{
            m++;
          }
          this.timeLines[k].push(this.glucoseDetails.timeLine[j]);
      }
      
    });
  }
  extractSummary(date:any){
    this.getGlucoseSummary(formatDate(date.value, 'yyyy-MM-dd','en-US'));
  }
  extractNutData( startDate:any,endDate:any){
     if(startDate.value != '' && endDate.value != ''){
      this.getNutritionData(formatDate(startDate.value, 'yyyy-MM-dd','en-US'),formatDate(endDate.value, 'yyyy-MM-dd','en-US'));
     }
  }
  extractSleepData(startDate:any,endDate:any){
     if(startDate.value != '' && endDate.value != ''){
      this.getSleepData(formatDate(startDate.value, 'yyyy-MM-dd','en-US'),formatDate(endDate.value, 'yyyy-MM-dd','en-US'));
     }
  }
  extractActivityData(startDate:any,endDate:any){
     if(startDate.value != '' && endDate.value != ''){
      this.getActivityData(formatDate(startDate.value, 'yyyy-MM-dd','en-US'),formatDate(endDate.value, 'yyyy-MM-dd','en-US'));
     }
  }
   getSleepData(startDate:any,endDate:any){
    this.dashboard.getSleepData(this.memberId,startDate,endDate).subscribe(response => {    
        this.sleep = response;
        this.sleep = this.sleep.result;
    });
   }
   getNutritionData(startDate:any,endDate:any){
    this.dashboard.getLifeStyle(this.memberId,startDate,endDate).subscribe(response => {    
        this.lifeStyle = response;
        this.lifeStyle = this.lifeStyle.result;
    });
  }
  getActivityData(startDate:any,endDate:any){
    this.dashboard.getActivity(this.memberId,startDate,endDate).subscribe(response => {    
        this.activity = response;
        this.activity = this.activity.result;
    });
  }
}
