export const environment = {
  env: 'Staging', 
  production: false,
  apiBaseUrl: 'https://dhruvaapi-staging.completumhealth.com/api/',
  apiKey: 'wTQiYUyz4qMHXbwgK36T2lHvDbr754dp',
   oldDhruva:"https://web-staging.completumhealth.com/dashboard",
   chatapi:"https://chatapi-staging.completumhealth.com",
   oldDhruvaUrl:"https://web-staging.completumhealth.com/",
 
};
