<div id="wait" *ngIf="isLoading == 1">
    <mat-spinner ></mat-spinner>
</div>
<div class="createMealDiv" *ngIf="iscreateSingleMeal">




<div class="row mt-5">
    <div class="title col-md-12">Generate Meal For</div>
    <div class="mainSub col-md-12 mt-2">Select multiple category</div>
</div>

<div class="row mt-4">
    <div class="col-2 ">
        <div class="colDiv " [ngClass]="{'mealActive': isBreakfast === 1}">
            <div class="titleName">Breakfast</div>
            <div class="crossMain" >
            <div class="cross" (click)="removeMealType(1,'Breakfast')" *ngIf="isBreakCross == 1" >X</div>
            <div class="cross" (click)="addMealType(1,'Breakfast')"  *ngIf="isBreakCross == 2"  >+</div>
            </div>
        </div> 
    </div>

    <div class="col-2 ">
       <div class="colDiv" [ngClass]="{'mealActive': isLunch === 1}">
            <div class="titleName">Lunch</div>
                <div class="crossMain">
                    <div class="cross" *ngIf="isLunchCross == 1" (click)="removeMealType(2,'Lunch')">X</div>
                    <div class="cross"  *ngIf="isLunchCross == 2"  (click)="addMealType(2,'Lunch')">+</div>
            </div>
        </div>
    </div>

    <div class="col-2 ">
        <div class="colDiv" [ngClass]="{'mealActive': isDinner === 1}">
             <div class="titleName">Dinner</div>
                 <div class="crossMain">
                    <div class="cross" *ngIf="isDinnerCross == 1" (click)="removeMealType(3,'Dinner')">X</div>
                    <div class="cross"  *ngIf="isDinnerCross == 2"  (click)="addMealType(3,'Dinner')">+</div>
             </div>
         </div>
     </div>

     <div class="col-2 ">
        <div class="colDiv" [ngClass]="{'mealActive': isSnack === 1}">
             <div class="titleName">Snack</div>
                 <div class="crossMain">
                    <div class="cross" *ngIf="isSnackCross == 1" (click)="removeMealType(4,'Snack')">X</div>
                    <div class="cross"  *ngIf="isSnackCross == 2"  (click)="addMealType(4,'Snack')">+</div>
             </div>
         </div>
     </div>
</div>

<hr class="mt-5">

<div class="row mt-5">
    <div class="title">Meal and Macros breakdown</div>
</div>

<div class="row mt-2 flex">
    <div class="flex mt-3">
        <div>
            <div>Calories</div>
            <div>
                <input type="text" class="form-control textField textContent" [(ngModel)]="cal" [ngModelOptions]="{standalone: true}"  placeholder="35%"/>
            </div>
        </div>
     &nbsp;&nbsp; 
     <div>
        <div>Protein</div>
        <div>
            <input type="text" [(ngModel)]="protien" [ngModelOptions]="{standalone: true}" class="form-control textField textContent"  placeholder="35%"/>
        </div>
    </div>
      &nbsp; &nbsp; 
      <div>
        <div>Fat</div>
        <div>
            <input type="text" [(ngModel)]="fat" [ngModelOptions]="{standalone: true}" class="form-control textField textContent"  placeholder="35%"/>
        </div>
    </div>
       &nbsp;&nbsp;
       <div>
        <div>Carbs</div>
        <div>
            <input type="text" [(ngModel)]="carbs" [ngModelOptions]="{standalone: true}" class="form-control textField textContent"  placeholder="35%"/> 
        </div>
    </div>
      
   </div>
</div>


<hr class="mt-5">

<app-preference [allPrefer]="preference" [selectedType]="checkSelectedMealType"></app-preference>

<div class="col-md-12 mt-5 textAlign">
    <button class="btn btnClass" (click)="createMealPlan()">Get Recipes</button> 
    &nbsp; &nbsp; 
    <!-- <button class="btn btnClass" (click)="backBtn1()">Back</button> -->
</div>
</div>
<div *ngIf="isResultSingle">
    <div class="row mt-5 ">
        <div class="col-6 mt-3" *ngFor="let recipes of this.mealResult;let i=index;">
            <div class="meal">
                <div class="row flex">
                    <div class="col mealType mealTitle">
                        {{recipes.type}}
                    </div>
                    <div class="col" style="text-align: right;">
                        <img style="width:10%" *ngIf="i != this.selectedRecp" (click)="chooseRecipe(i)" src="/assets/img/unselectedRecipe.png" />
                        <img style="width:10%"  *ngIf="i == this.selectedRecp" (click)="chooseRecipe(i)" src="/assets/img/selectedRecipe.png" />
                        
                    </div>
                </div>
                <div class="row flex mt-4">
                    <!-- <div class="col-2">
                        <img src="/assets/img/mealpic.png"/>
                    </div> -->
                    <div class="col">
                        <div class="col-md-8 ml-4">
                            <div class="mealName">{{recipes.name}},  {{getType(recipes.mealPlanServing.unit,recipes.mealPlanServing.size,recipes.mealPlanServing.quantity)}}
                                <!-- <span *ngIf="recipes.mealPlanServing.unit === 'grams' ">{{recipes.mealPlanServing.size}} {{recipes.mealPlanServing.unit}}</span>
                    <span *ngIf="recipes.mealPlanServing.unit !== 'grams'">{{recipes.mealPlanServing.quantity}} {{recipes.mealPlanServing.unit}}</span>
                    <span *ngIf="recipes.mealPlanServing.unit === 'gram'">{{recipes.mealPlanServing.size}} {{recipes.mealPlanServing.unit}}</span>
                    <span *ngIf=" recipes.mealPlanServing.unit !== 'gram'">{{recipes.mealPlanServing.quantity}} {{recipes.mealPlanServing.unit}}</span> -->
                </div>
                        </div>
                        <div class="row mt-5 ">
                            <div class="col-sm">
                                <div class="col-md-12 flex">
                                    <img class="imgClass" src="/assets/img/calories.png"/> &nbsp;
                                    <div class="nutriTitle mt-2">Calories</div>
                                </div>
                                <div class="col-md-12 mt-2 val ml-4">
                                    {{recipes.nutritionBreakdown.calories.toFixed(2)}}
                                </div>
                                <div class="col-md-12 mt-2 unit ml-4">
                                    cal
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="col-md-12 flex">
                                    <img class="imgClass" src="/assets/img/protien.png"/> &nbsp;
                                    <div class="nutriTitle mt-2">Protien</div>
                                </div>
                                <div class="col-md-12 mt-2 val ml-4">
                                    {{recipes.nutritionBreakdown.protein.toFixed(2)}}
                                </div>
                                <div class="col-md-12 mt-2 unit ml-4">
                                    grams
                                </div>
                            </div>
            
                            <div class="col-sm">
                                <div class="col-md-12 flex">
                                    <img class="imgClass" src="/assets/img/carbs.png"/> &nbsp;
                                    <div class="nutriTitle mt-2">Carbs</div>
                                </div>
                                <div class="col-md-12 mt-2 val ml-4">
                                    {{recipes.nutritionBreakdown.carbs.toFixed(2)}}
                                </div>
                                <div class="col-md-12 mt-2 unit ml-4">
                                    grams
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="col-md-12 flex">
                                    <img class="fat" src="/assets/img/fat1.png"/> &nbsp;
                                    <div class="nutriTitle mt-2">Fat</div>
                                </div>
                                <div class="col-md-12 mt-2 val ml-3">
                                    {{recipes.nutritionBreakdown.fat.toFixed(2)}}
                                </div>
                                <div class="col-md-12 mt-2 unit ml-3">
                                    grams
                                </div>
                            </div>
                            
                            </div>
            
                            
                    </div>
                </div>
                <!-- <div class="col-md-12 textAlign mt-4 flex">
                     <button class="btn btnClass"   (click)="viewMealPlan(mealResult)" >View More Details</button>
                    </div> -->
               
            </div>
        </div> 
       
    </div>

    <div class="col-md-12 mt-5 textAlign">
        <button class="btn btnClass" (click)="addRecipe()">Save</button> 
        &nbsp; &nbsp; 
        <button class="btn btnClass" (click)="backBtn()">Back</button>
    </div>

</div>
