import { Component } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
@Component({
  selector: 'app-search-meal',
  standalone: true,
  imports: [BasicDetailsComponent],
  templateUrl: './search-meal.component.html',
  styleUrl: './search-meal.component.css'
})
export class SearchMealComponent {

}
