import { Component, Input } from '@angular/core';
import { NotesComponent } from '../life-style/notes/notes.component';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-activity',
  standalone: true,
  imports: [NotesComponent,HighchartsChartModule],
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.css'
})

export class ActivityComponent {
  @Input() activity:any;
  Highcharts: typeof Highcharts = Highcharts;
  coptions: Highcharts.Options = {};
  stepsTakenPerct:any;
  stepsRemainingPerct:any;
  stepsStatus:any;
  calsStatus:any;
  stepsColor:any;
  calsBtnColor:any;
  totalSteps:any;
  text:any;
  ngOnChanges(): void {
    this.stepsTakenPerct=0;
    this.stepsRemainingPerct=0;
    this.stepsStatus='';
    this.calsStatus='';
    this.totalSteps='---';
    this.stepsColor='white';
    this.calsBtnColor='white';
    if(this.activity && this.activity.stepsStatus){
    this.stepsTakenPerct = Math.round(this.activity.totalSteps/this.activity.stepsGoal*100);
    this.stepsRemainingPerct = 100-this.stepsTakenPerct;
    this.stepsStatus = this.activity.stepsStatus;
    this.stepsColor = this.getColor(this.stepsStatus);
    this.calsBtnColor = this.getColor(this.activity.calsBurntStatus);
    this.totalSteps = this.activity.totalSteps;
    this.calsStatus = this.activity.calsBurntStatus;
    }
    this.text = '<div style="font-weight:400;font-family:"DM Sans-Regular", Helvetica !important;">Total Steps</div><br><div style="font-weight:900;margin-top:-17px;font-family:"DM Sans-Regular", Helvetica !important;">'
                +this.totalSteps+'</div><br><div style="margin-top:-15px;border-radius: 20px;padding:9px;width:170px;color:white;font-size:17px;font-weight:400; background-color:'
                +this.stepsColor+';font-family:"DM Sans-Regular", Helvetica !important;">'+this.stepsStatus+'</div>';
    this.coptions =  {
    chart: {
        plotBorderWidth: 0,
        plotShadow: false
    },
    credits: {
    enabled: false
  },
      
    title: {
        text: this.text,
        style: {
          textAlign: 'center',
        },
        verticalAlign: 'middle',
        useHTML: true,
        y: 90
    },
    tooltip:{
       formatter: function() {
        return this.key +' : '+ this.y + ' %';
      },  
      backgroundColor: 'rgba(0,0,0, 0.6)',
      borderWidth: 1,
      shadow: true,
      style: {
        color: '#fff',
        fontSize: '10pt',
        fontFamily: 'Poppins, sans-serif'
      }
    },
   
    
    plotOptions: {
        pie: {
            dataLabels: {
                enabled: false,
                distance: -50,
                style: {
                    fontWeight: 'bold',
                    color: 'white',
                }
            },
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            colors: ['#007AFF', '#F9F9F9'], 
            size: '100%'
        }
    },
    series: [{
        type: 'pie',
        name: '',
        innerSize: '70%',
        data: [
            ['Steps Taken', this.stepsTakenPerct],
            ['Steps Remaining',this.stepsRemainingPerct]
        ]
    }]
};
  }
  getColor(status:any){
  if(status == 'Excellent'){
      return '#20AA4A';}
    else if(status == 'Good'){
      return '#D0AF00';
    }
    else if(status == 'Needs Attention'){
      return '#FF3939';
    }
}
}
