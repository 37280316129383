<div class="maintitle mt-3 ml-2">Upload File</div>
<div class="subTitle mt-3 ml-2">Upload your recent blood report or prescription to validate your overall health, This could help us in many ways.</div>

<div class="col-md-12" style="display: flex;">
    <div class="col-md-6 uploadBox float mt-4">
        <div >
            <img src="/assets/img/upload.png"/>
        </div>
        <div class="uploadContent mt-2">Upload your recent report</div>
       
            <button class="btnCl mt-3">Click here to upload</button>
        

    </div>

    <div class="col-md-6 uploadBox float mt-4 ml-5">
        <div >
            <img src="/assets/img/upload.png"/>
        </div>
        <div class="uploadContent mt-2">Upload your recent prescription</div>
       
            <button class="btnCl mt-3">Click here to upload</button>
        

    </div>
</div>
<div class="col-md-12 mt-2" >
    <div class="question mt-5">1. Do you have any family history of chronic disease, such as heart disease or diabetes, or health conditions such as high blood pressure or high cholesterol?</div>
    <div class="col-md-10">
        <cdk-accordion class="example-accordion">
            @for (mutlipleSelect of mutliple; track mutlipleSelect; let index = $index) {
        <cdk-accordion-item
        
      #accordionItem="cdkAccordionItem"
      class="example-accordion-item"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index">
      <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
        {{ mutlipleSelect.question }}
       
      </div>
      <div
        class="example-accordion-item-body"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index">
        <div class="options mt-4" >
        <ul>
            <li *ngFor="let options of mutlipleSelect.options; let i = index"         
               >
              {{ options}}
            </li>
        </ul>
        </div>
      </div>
    </cdk-accordion-item>
}
</cdk-accordion>
    
      
    </div>
  
</div>

<div class="col-md-12">
    <div class="col-md-6 float mt-5" *ngFor="let options of plainText; let i = index">
        <div class="question ">{{i+2}}. {{options.question}}:</div>
        <textarea  class="form-control mt-3 textField" placeholder="Type Here...">{{options.question}}</textarea>
    </div>
   

    <div class="col-md-6 float mt-5" *ngFor="let options of singleOption; let j = index">
        <div class="question ">{{j+2}}. {{options.question}}:</div>
        <select class="form-control">
            <option value="{{value.val}}" *ngFor="let value of options.options let j = index">{{value.key}}</option>
        </select>
        
    </div>
   
    <div class="col-md-12 float mt-5">
        <button type="submit" class="btn btnClass">Save</button>
     </div>
     
</div>