import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { Input } from '@angular/core';
import { MealPlanService } from '../meal-plan.service';
@Component({
  selector: 'app-meal-member-basic-details',
  standalone: true,
  imports: [NgIf],
  templateUrl: './meal-member-basic-details.component.html',
  styleUrl: './meal-member-basic-details.component.css'
})
export class MealMemberBasicDetailsComponent {
@Input() memberDetail:any;
//@Input() actualWeight:any;

memberDetails:any=[];
actualWeight:any = 0; 
constructor(private mealService:MealPlanService) {
      
}
ngOnInit(): void {
 
  this.getMemberDetails( this.memberDetail);
  this.mealService.getVital(this.memberDetail).subscribe(response => { 
   this.actualWeight =response;
  //console.log( this.actualWeight);
   if(this.actualWeight.result.bmiData !== null){
    this.actualWeight = this.actualWeight.result.bmiData.weight;
   }else{
    this.actualWeight = 0;
   }
  });
}
getMemberDetails(id:any){
  this.mealService.getMemberDetails(id).subscribe(response => { 
    this.memberDetails = response;
    this.memberDetails = this.memberDetails.result;
   // console.log(this.memberDetails);
});
}
}
