<!-- 
<mat-dialog-content class="mat-typography">
  <div class="row" style="width:100%">
    
    <div class="col-md-2"><img class="patientImg" mat-card-image src="assets/img/patient.png" /></div>
    <div class="col-md-8 name ml-2 mt-1" >
      <div class="col-md-12 name">
          Kevin
      </div>
      <div class="col-md-12 age" >
        Age : 26
    </div>
  


    </div>

    
  </div>

  <div class="row mt-3">
    <div class="col-md-1 mt-2"><img class="bloodIcon" src="assets/img/blood-pressure 1.png" /></div>
    <div class="col-md-8">
        <div class="col-md-12">
            <div class="titleBlood">Blood Pressure</div>
        </div>
        <div class="col-md-12">
            <div class="bloodPressure">50</div>
        </div>
    </div>
    <div class="col-md-2"><img class="bloodIcon" src="assets/img/Up.png" /></div>
</div>

<div class="row mt-3">
    <div class="col-md-1 mt-2"><img class="bloodIcon" src="assets/img/Blood Glucose.png" /></div>
    <div class="col-md-8">
        <div class="col-md-12">
            <div class="titleBlood">Blood Glucose</div>
        </div>
        <div class="col-md-12">
            <div class="bloodPressure">200 mg/dl</div>
        </div>
    </div>
    <div class="col-md-2"><img class="bloodIcon" src="assets/img/Up.png" /></div>
</div>

<div class="row mt-3">
  <select class="form-control">
      <option>Immaual</option>
  </select>
</div>

<div class="row mt-3">
  <textarea class="form-control" matInput row="10"></textarea>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  
  <button class="btnClass" onclick="onNoClick()"  row="30" mat-button mat-dialog-close>Close</button> &nbsp; &nbsp;
  <button class="btnClass"  row="30" mat-button mat-dialog-close>Submit</button>
  
</mat-dialog-actions> -->


<mat-dialog-content class="mat-typography">
  <div class="row" style="width:100%">
    <div class="col-md-12">
        <h4>Are you to mark resolved?</h4> 
    </div>    
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">  
  <button class="btnClass" onclick="onNoClick()"  row="30" mat-button mat-dialog-close>Close</button> &nbsp; &nbsp;
  <button class="btnClass" (click)="markResolved()"  row="30" mat-button >Mark Resolved</button>  
</mat-dialog-actions>