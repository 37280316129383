import { Component } from '@angular/core';
import { Input } from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
@Component({
  selector: 'app-nutrition',
  standalone: true,
  imports: [MatProgressBarModule],
  templateUrl: './nutrition.component.html',
  styleUrl: './nutrition.component.css'
})
export class NutritionComponent {
    @Input() nutri:any;
}
