<div class="col-md-12">
<div class="col-md-12 float">
     <div class="col-md-12 glucoseSub mt-2">Calories and Macros</div>
    <app-nutrition [nutri]="lifeStyle"></app-nutrition>
    <div class="col-md-12 mt-5">
        <div class="col-md-12 labReport">Eating Pattern</div>
        
    </div>
    </div>
    <div class="col-md-12 float">
    <app-eating [eat]="lifeStyle" class="mt-4"></app-eating>
</div>
<!-- <div class="col-md-4 float">
    <div class="noteSection">Notes Section</div>
    <app-notes></app-notes>
</div> -->

</div>