<div class="col-md-12 title" >
    {{memberbasic.firstName}} {{memberbasic.lastName}}
</div>
<div class="col-md-12" >
    <span class="spanContent">{{memberbasic.plan?memberbasic.plan : 'No Plan Found'}}</span> &nbsp; | &nbsp; <span class="spanTitle">Age:</span>&nbsp;<span class="spanContent">{{memberbasic.age}}</span> &nbsp; | &nbsp; <span class="spanTitle">DOB:</span>&nbsp;<span class="spanContent">{{memberbasic.dateOfBirth?memberbasic.dateOfBirth.split('T')[0]:''}}</span> &nbsp; | &nbsp; <span class="spanTitle">Height:</span>&nbsp;<span class="spanContent">{{memberbasic.height}}</span> &nbsp; | &nbsp; <span class="spanTitle">Weight:</span>&nbsp;<span class="spanContent">{{vital && vital.bmiData?vital.bmiData.weight:'---'}}</span> &nbsp; | &nbsp; <span class="spanTitle">Level of Care:</span>&nbsp;<span class="spanContent">{{memberbasic.memberLevel?memberbasic.memberLevel:'---'}}</span> &nbsp; | &nbsp; <span class="spanTitle">BMI:</span>&nbsp;<span class="spanContent">{{vital && vital.bmiData?vital.bmiData.bmi:'---'}}</span>
     <span [ngClass]="memberbasic.plan!=null && memberbasic.plan.length>17?'spanDate_new':'spanDate'"><mat-form-field style="width: 150px;">
                    <mat-label>Pick date</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="currentDate" #dateRangeEnd (dateChange)="extractByDate(dateRangeEnd)">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
     </mat-form-field></span>
</div>
<div class="row mt-1" style="display: flex;">
    <div class="col-md-2 tags ml-3" *ngFor="let c of conditions">{{c}}</div>
</div>