import { Component } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { MemberTeamCountComponent } from '../member-team-count/member-team-count.component';
import {MatButtonModule} from '@angular/material/button';
import { MeetingComponent } from '../meeting/meeting.component';
import { MemberLevelComponent } from './member-level/member-level.component';
import { Input } from '@angular/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatLabel } from '@angular/material/form-field';
import { MatHint } from '@angular/material/form-field';
import { MatDateRangeInput } from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { 
  formatDate 
 } 
  from '@angular/common';
  import { NgClass } from '@angular/common';
 import { DashboardService } from '../dashboard.service';
 import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-member-statistics',
  standalone: true,
  imports: [NgClass,MatInputModule,MatNativeDateModule,MatDateRangeInput,MatHint,MatLabel,MatCardModule,MemberTeamCountComponent,MatButtonModule,MeetingComponent,MemberLevelComponent,MatFormFieldModule,MatDatepickerModule],
  templateUrl: './member-statistics.component.html',
  styleUrl: './member-statistics.component.css'
})
export class MemberStatisticsComponent {
  
  @Input() eventData:any;
  oldDhruva = environment.oldDhruvaUrl;
  events:any=[];
  currentDate:any = formatDate(Date.now(), 'yyyy-MM-dd','en-US');
  memberCounts:any=[];

  constructor(private dashboard:DashboardService) {
  }
  ngOnInit(): void {      
    this.getTotalCount();
    //this.getEvent(this.currentDate);
  }

 

  getEvent(date:any){
    this.dashboard.getEvents(date).subscribe(response => { 
        this.events= response;
        this.eventData = this.events.result;
       // console.log(this.events);
    });
  }

  
  public filterByDate(date:HTMLInputElement){
     
      this.getEvent(this.dateFormat(date.value));
  }
  dateFormat(date:any){
    return formatDate(date, 'yyyy-MM-dd','en-US');
  }

  getTotalCount(){
    this.dashboard.getCounts().subscribe(response => { 
      this.memberCounts= response;
    
  });
  }
}
