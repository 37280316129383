import { Component, Input } from '@angular/core';
import { NutritionComponent } from './nutrition/nutrition.component';
import { EatingComponent } from './eating/eating.component';
import { MemberDashboardService } from '../member-dashboard.service';
import { ActivatedRoute } from '@angular/router';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-life-style',
  standalone: true,
  imports: [NutritionComponent,EatingComponent],
  templateUrl: './life-style.component.html',
  styleUrl: './life-style.component.css'
})
export class LifeStyleComponent {
  @Input() lifeStyle:any;
 
 

}
